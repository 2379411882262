import moment from 'moment'
import { useState } from 'react'
import { observer } from 'mobx-react'

import { Separator } from '../../components/app-layout/separator/separator'
import { Header } from '../../components/app-layout'
import { UncompletedActionsCarousel } from '../../components/uncompleted-actions-carousel/component'
import { Statistics } from './components/statistics/statistics'
import { Welcome } from './components/welcome/welcome'
import { BecomeAffiliateModal } from '../../components/affiliate/become-affiliate-modal/become-affiliate-modal'
import { TcModal } from '../../components/affiliate/tc-modal/tc-modal'
import { useRedirect } from '../../services/router'
import { routes } from '../../routes'
import { useMutation, useStore } from '../../utils/mst-hooks'
import './home.scss'

export const Home = observer(({ hideLogo }: { hideLogo: boolean }) => {
  const currentFormattedDate = moment().format('ddd, MMM D')

  const [showBecomeAffiliateModal, setShowBecomeAffiliateModal] = useState(false)
  const [showAffiliateTcModal, setShowAffiliateTcModal] = useState(false)

  const redirect = useRedirect()

  const { markClientAsAffiliate } = useStore().data
  const [, markClientAsAffiliateRequest] = useMutation(markClientAsAffiliate, {
    onSuccess: () => {
      redirect(routes.affiliateDashboard, {})
    },
  })

  return (
    <div className="Home">
      <Header hideLogo={hideLogo} />
      <Separator.Vertical height={40} />
      <span className="Home__date">{currentFormattedDate}</span>
      <Welcome />
      <Separator.Vertical height={23} />
      <UncompletedActionsCarousel openBecomeAffiliateModal={() => setShowBecomeAffiliateModal(true)} />
      <Separator.Vertical height={37} />
      <Statistics />
      <Separator.Vertical height={200} />
      <BecomeAffiliateModal
        showBecomeAffiliateModal={showBecomeAffiliateModal}
        setShowBecomeAffiliateModal={setShowBecomeAffiliateModal}
      />
      <TcModal
        onContinue={() => {
          setShowBecomeAffiliateModal(false)
          setShowAffiliateTcModal(false)
          markClientAsAffiliateRequest({})
        }}
        showModal={showAffiliateTcModal}
        setShowModal={setShowAffiliateTcModal}
      />
    </div>
  )
})
