import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'

import { useStore, useQuery, useDelayedQuery } from '../../../../../../utils/mst-hooks'
import { CalendarHeader } from '../calendar-header/calendar-header'
import { CalendarWeek } from '../calenar-week/calendar-week'
import { CalendarSchedule } from '../calendar-schedule/calendar-schedule'
import { CalendarModificationsControl } from '../calendar-modifications-control/calendar-modifications-control'
import { getFirstDayOfWeek, getRelativeDay } from '../../../../../../utils/datetime-utils'
import LoadingSpinner from '../../../../../../components/loading-spinner/loading-spinner'
import './calendar-wrapper.scss'

function CalendarWrapperComponent({ initialDate }: { initialDate: Date }) {
  const { clearScheduleData, getSchedule, fetchShiftCancelReasons } = useStore().data
  const [selectedDate, setSelectedDate] = useState(getFirstDayOfWeek(initialDate))
  const [locationId, setLocationId] = useState<number | null>(null)
  const { enableQuery: loadSchedule, query: scheduleQuery } = useDelayedQuery(() =>
    getSchedule(selectedDate, locationId),
  )
  const { isLoading: cancelReasonsLoading } = useQuery(fetchShiftCancelReasons)

  useEffect(() => {
    clearScheduleData()
  }, [])

  useEffect(() => {
    loadSchedule()
  }, [selectedDate, locationId])

  const fetchingData = scheduleQuery.isLoading || cancelReasonsLoading

  return (
    <div className="CalendarWrapper">
      <div className="CalendarWrapper__calendar">
        <CalendarHeader
          month={selectedDate.toLocaleString(navigator.language, { month: 'long' })}
          year={String(selectedDate.getFullYear())}
          locationId={locationId}
          setLocationId={setLocationId}
          onPreviousWeekPress={() => {
            setSelectedDate(getRelativeDay(selectedDate, -7))
          }}
          onNextWeekPress={() => {
            setSelectedDate(getRelativeDay(selectedDate, +7))
          }}
        />
        <CalendarWeek firstWeekDate={selectedDate} />
        {fetchingData ? <LoadingSpinner /> : <CalendarSchedule firstWeekDate={selectedDate} />}
      </div>
      <div className="CalendarWrapper__spacer" />
      <CalendarModificationsControl />
    </div>
  )
}

export const CalendarWrapper = observer(CalendarWrapperComponent)
