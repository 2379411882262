import { useState } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useRedirect } from '../../../../../services/router'
import { routes } from '../../../../../routes'
import { Separator } from '../../../../../components/app-layout/separator/separator'
import { Button } from '../../../../../components/button/button'
import { useStore } from '../../../../../utils/mst-hooks'
import { useDraftJobsStore } from '../../../../../models-ui/order/store'
import { JobField } from './components/job-field/job-field'
import { ScheduleType } from '../../../../../models-ui/order/store/job-fields/schedule/types'
import { Genders } from '../../../../../models-ui/order/store/job-fields/gender'
import './duplicate-job.scss'
import { Select } from '../../../../../components/form/select/select'
import { Experience } from '../../../../../models/seniority-level'
import { getCurrentMarketConfig } from '../../../../../services/market-service'

type DuplicateJobProps = {
  orderId: string
  jobId: string
  setJobId: (jobId: string) => void
  showSelectJob: boolean
  jobsList: {
    id: number
    category_name: string
    quantity: number
  }[]
}

export const DuplicateJob = observer(({ orderId, jobId, setJobId, jobsList, showSelectJob }: DuplicateJobProps) => {
  const [designationSelected, setDesignationSelected] = useState(false)
  const [quantitySelected, setQuantitySelected] = useState(false)
  const [locationSelected, setLocationSelected] = useState(false)
  const [publicTransportAccessibilitySelected, setPublicTransportAccessibilitySelected] = useState(false)
  const [scheduleSelected, setScheduleSelected] = useState(false)
  const [requirementsSelected, setRequirementsSelected] = useState(false)
  const [jobDescriptionSelected, setJobDescriptionSelected] = useState(false)
  const [experienceSelected, setExperienceSelected] = useState(false)
  const [dresscodeSelected, setDresscodeSelected] = useState(false)
  const [languageSelected, setLanguageSelected] = useState(false)
  const [genderSelected, setGenderSelected] = useState(false)
  const [documentsSelected, setDocumentsSelected] = useState(false)
  const [manualSelectionSelected, setManualSelectionSelected] = useState(false)
  const [accreditationSelected, setAccreditationSelected] = useState(false)
  const [accomodationSelected, setAccommodationSelected] = useState(false)
  const [boardingSelected, setBoardingSelected] = useState(false)
  const [interviewSeleted, setInterviewSelected] = useState(false)

  const { createDraftJob } = useDraftJobsStore()

  const { getOrderIdJob } = useStore().data
  const job = getOrderIdJob(orderId, jobId)

  const redirect = useRedirect()

  const { t } = useTranslation()

  const handleDuplicate = () => {
    if (!job) {
      throw new Error('Job not found, unable to duplicate')
    }

    const draftJob = createDraftJob()
    draftJob.setDuplicatedJob(parseInt(jobId))

    if (designationSelected) {
      draftJob.category.set(job.work_category_id)
    }
    if (quantitySelected) {
      draftJob.quantity.set(job.quantity)
    }
    if (locationSelected) {
      draftJob.location.set({ id: job.client_location_id, name: job.client_location_name })
    }
    if (publicTransportAccessibilitySelected) {
      draftJob.transport.set(job.public_transport_accessibility)
    }
    if (scheduleSelected) {
      const firstDateKey = job.intervals.keys().next().value
      const weekdayLength = 3 // 'Mon', 'Tue', etc
      const scheduleType = firstDateKey.length > weekdayLength ? ScheduleType.daily : ScheduleType.weekly
      draftJob.schedule.setStartDate(job.start_date)
      draftJob.schedule.setEndDate(job.end_date)
      draftJob.schedule.setScheduleType(scheduleType)
      draftJob.schedule.sameTimeSchedule.setIsSameTiming(false)
      Array.from(toJS(job.intervals)).forEach(([date, dateObj]) => {
        draftJob.schedule.distinctTimeSchedule.setDateTime({
          date,
          start_time: dateObj.start_time.slice(0, 5),
          end_time: dateObj.end_time.slice(0, 5),
          selected: true,
        })
      })
    }
    if (requirementsSelected && designationSelected) {
      draftJob.requirements.set(toJS(job.work_requirement_ids))
    }
    if (jobDescriptionSelected) {
      draftJob.description.set(job.instructions)
    }
    if (experienceSelected) {
      const jobExperience = String(job.seniority_level) as Experience
      draftJob.experience.set(jobExperience)
    }
    if (dresscodeSelected) {
      draftJob.dresscode.set(job.uniform_id)
    }
    if (languageSelected && job.filter.languages) {
      draftJob.languages.set(toJS(job.filter.languages))
      draftJob.languages.useLanguages(true)
    }
    if (genderSelected && job.filter.gender) {
      draftJob.gender.set(String(job.filter.gender) as Genders)
      draftJob.gender.useGender(true)
    }
    if (documentsSelected && job.filter.certificates) {
      draftJob.documents.set(toJS(job.filter.certificates))
      draftJob.documents.useDocuments(true)
    }
    if (manualSelectionSelected && job.assignment_type === 1) {
      draftJob.manualSelection.setManualSelection(true)
      draftJob.manualSelection.useManualSelection(true)
    }
    if (interviewSeleted) {
      draftJob.manualSelection.useInterview(true)
    }
    if (accreditationSelected && job.is_accreditation_required) {
      draftJob.accreditation.set(true)
      draftJob.accreditation.useAccreditation(true)
    }
    if (accomodationSelected && job.is_accommodation_offered) {
      draftJob.accommodation.useAccommodation(true)
    }
    if (boardingSelected) {
      draftJob.boarding.useBoarding(true)
      draftJob.boarding.set(job.boarding_type)
    }

    if (designationSelected) {
      redirect(routes.jobDraftDetails, { orderId, draftJobId: draftJob.id, stepNumber: String(2) })
    } else {
      redirect(routes.setJobCategory, { orderId, draftJobId: draftJob.id, stepNumber: String(2) })
    }
  }

  return (
    <div className="DuplicateJob">
      <Separator.Vertical height={13} />
      <div className="DuplicateJob__header">
        <span className="DuplicateJob__header__title">
          {t('order_draft.what_to_copy', { category: job?.category_name })}
        </span>
        <span className="DuplicateJob__header__subtitle">{t('order_draft.select_preferences')}</span>
      </div>
      {showSelectJob && (
        <>
          <Separator.Vertical height={25} />
          <Select
            label={t('order.job_to_duplicate')}
            value={jobId}
            onChange={value => setJobId(value)}
            options={jobsList.map(jobItem => ({
              key: String(jobItem.id),
              value: String(jobItem.id),
              label: `#${jobItem.id} - ${jobItem.category_name} (${jobItem.quantity} ${t('order_draft.workers')})`,
            }))}
          />
        </>
      )}
      <Separator.Vertical height={25} />
      <JobField
        label="Designation"
        selected={designationSelected}
        onToggle={() => {
          setDesignationSelected(prevValue => {
            if (prevValue === true && requirementsSelected) {
              setRequirementsSelected(false)
            }
            return !prevValue
          })
        }}
      />
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.quantity')}
        selected={quantitySelected}
        onToggle={() => {
          setQuantitySelected(state => !state)
        }}
      />
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.location')}
        selected={locationSelected}
        onToggle={() => {
          setLocationSelected(state => !state)
        }}
      />
      {getCurrentMarketConfig().order.includePublicTransportationField && (
        <>
          <Separator.Vertical height={25} />
          <JobField
            label={t('order_draft.public_transport_accessibility')}
            selected={publicTransportAccessibilitySelected}
            onToggle={() => {
              setPublicTransportAccessibilitySelected(state => !state)
            }}
          />
        </>
      )}
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.schedule')}
        selected={scheduleSelected}
        onToggle={() => {
          setScheduleSelected(state => !state)
        }}
      />
      {designationSelected && (
        <>
          <Separator.Vertical height={25} />
          <JobField
            label={t('order_draft.requirements')}
            selected={requirementsSelected}
            onToggle={() => {
              setRequirementsSelected(state => !state)
            }}
          />
        </>
      )}
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.job_description')}
        selected={jobDescriptionSelected}
        onToggle={() => {
          setJobDescriptionSelected(state => !state)
        }}
      />
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.seniority_level')}
        selected={experienceSelected}
        onToggle={() => {
          setExperienceSelected(state => !state)
        }}
      />
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.dresscode')}
        selected={dresscodeSelected}
        onToggle={() => {
          setDresscodeSelected(state => !state)
        }}
      />
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.language')}
        selected={languageSelected}
        onToggle={() => {
          setLanguageSelected(state => !state)
        }}
      />
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.gender')}
        selected={genderSelected}
        onToggle={() => {
          setGenderSelected(state => !state)
        }}
      />
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.documents')}
        selected={documentsSelected}
        onToggle={() => {
          setDocumentsSelected(state => !state)
        }}
      />
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.manual_selection')}
        selected={manualSelectionSelected}
        onToggle={() => {
          setManualSelectionSelected(state => !state)
        }}
      />
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.interview_required')}
        selected={interviewSeleted}
        onToggle={() => {
          setInterviewSelected(state => !state)
        }}
      />
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.accreditation_requried')}
        selected={accreditationSelected}
        onToggle={() => {
          setAccreditationSelected(state => !state)
        }}
      />
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.accommodation_offered')}
        selected={accomodationSelected}
        onToggle={() => {
          setAccommodationSelected(state => !state)
        }}
      />
      <Separator.Vertical height={25} />
      <JobField
        label={t('order_draft.boarding_offered')}
        selected={boardingSelected}
        onToggle={() => {
          setBoardingSelected(state => !state)
        }}
      />
      <Separator.Spacer minHeight={31} />
      <Button title={t('order_draft.duplicate_job')} onClick={handleDuplicate} className="DuplicateJob__button" />
    </div>
  )
})
