import downArray from '../../../../../../../components/assets/down-arrow-grey.svg'
import './collapse-toggle.scss'

export function CollapseToggle() {
  return (
    <div className="CollapseToggle">
      <img src={downArray} className="CollapseToggle__img" />
      <img src={downArray} className="CollapseToggle__img flipped" />
    </div>
  )
}
