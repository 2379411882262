import { types } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const SignUpWithOrderDataModel = types.model('SignUpWithOrderData', {
  workCategoryId: types.maybeNull(types.number),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  companyName: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  allow_marketing_emails: types.maybeNull(types.number),
})

export const signUpWithOrderActions = (self: DataStore) => ({
  setWorkCategoryId: (workCategoryId: number) => {
    self.signUpWithOrderData.workCategoryId = workCategoryId
  },
  setFirstName: (firstName: string) => {
    self.signUpWithOrderData.firstName = firstName
  },
  setLastName: (lastName: string) => {
    self.signUpWithOrderData.lastName = lastName
  },
  setCompanyName: (companyName: string) => {
    self.signUpWithOrderData.companyName = companyName
  },
  setPhone: (phone: string) => {
    self.signUpWithOrderData.phone = phone
  },
  setEmail: (email: string) => {
    self.signUpWithOrderData.email = email
  },
  toggleEmailAgreement: () => {
    self.signUpWithOrderData.allow_marketing_emails = self.signUpWithOrderData.allow_marketing_emails ? 0 : 1
  },
})
