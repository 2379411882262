import { useTranslation } from 'react-i18next'

import leftArrowSvg from '../../../../../../components/assets/arrow-left.svg'

type InputProps = {
  onPreviousWeekPress: () => void
  onNextWeekPress: () => void
  additionalContainerClassName?: string
}
export const WeekToggle = ({ onPreviousWeekPress, onNextWeekPress, additionalContainerClassName }: InputProps) => {
  const { t } = useTranslation()

  return (
    <div className={`CalendarHeader__weekToggle ${additionalContainerClassName}`}>
      <span className="CalendarHeader__label">{t('all_jobs.week')}</span>
      <div className="CalendarHeader__right__toggle">
        <div onClick={onPreviousWeekPress} className="CalendarHeader__right__toggle__arrowContainer">
          <img src={leftArrowSvg} className="CalendarHeader__right__toggle__arrowContainer__arrow" />
        </div>
        <div className="CalendarHeader__right__toggle__separator" />
        <div onClick={onNextWeekPress} className="CalendarHeader__right__toggle__arrowContainer">
          <img src={leftArrowSvg} className="CalendarHeader__right__toggle__arrowContainer__arrow flipped" />
        </div>
      </div>
    </div>
  )
}
