import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { useQuery, useStore } from '../../../../../../utils/mst-hooks'
import './calendar-location-filter.scss'
import { BusinessLocationSnapshot } from '../../../../../../models/location'
import { Select } from '../../../../../../components/form/select/select'

function CalendarLocationFilterComponent({
  locationId,
  setLocationId,
}: {
  locationId: number | null
  setLocationId: (value: number | null) => void
}) {
  const { t } = useTranslation()

  const { locationsDataView, getLocations, getLocationNameById, getLocationByName } = useStore().data

  const { isLoading: locationsLoading } = useQuery(getLocations)

  const allLocationsTitle = t('calendar.all_locations')
  const locationOptions =
    locationsDataView.length > 0
      ? [
          {
            key: null,
            label: allLocationsTitle,
            value: allLocationsTitle,
          },
          ...locationsDataView.map(location => ({
            key: String(location.id),
            label: location.name,
            value: location.name,
          })),
        ]
      : []

  if (locationsLoading) {
    return null
  }

  return (
    <div className="CalendarLocationFilter">
      <Select
        label={'Filter location'}
        value={locationId !== null ? getLocationNameById(String(locationId)) : null}
        onChange={value => {
          const id =
            value !== allLocationsTitle ? (getLocationByName(String(value)) as BusinessLocationSnapshot).id : null
          setLocationId(id)
        }}
        options={locationOptions}
        className="CalendarLocationFilter__select"
      />
    </div>
  )
}

export const CalendarLocationFilter = observer(CalendarLocationFilterComponent)
