import './calendar.scss'
import moment from 'moment'
import { useState, useEffect, forwardRef, RefObject, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Calendar as ReactCalendar } from 'react-calendar'

import { Select } from '../form/select/select'
import { LANGUAGE_EN, marketService } from '../../services/market-service'
import 'react-calendar/dist/Calendar.css'

const months = [
  'calendar.month_january',
  'calendar.month_february',
  'calendar.month_march',
  'calendar.month_april',
  'calendar.month_may',
  'calendar.month_june',
  'calendar.month_july',
  'calendar.month_august',
  'calendar.month_september',
  'calendar.month_october',
  'calendar.month_november',
  'calendar.month_december',
]

type InputProps = {
  value: Date
  setValue: (value: Date | null) => void
  minDate: Date | undefined
  maxDate: Date | undefined
  containerClassName?: string
  containerStyle?: object
}

const Calendar = forwardRef(
  ({ value, setValue, minDate, maxDate, containerClassName, containerStyle }: InputProps, ref) => {
    const years = useMemo(() => {
      const currentYear = Number(moment().format('YYYY'))
      return [
        {
          key: currentYear,
          value: String(currentYear),
        },
        {
          key: currentYear + 1,
          value: String(currentYear + 1),
        },
      ]
    }, [])

    const { t } = useTranslation()

    const [chosenMonth, setChosenMonth] = useState<number>(new Date().getMonth())
    const [chosenYear, setChosenYear] = useState<number>(new Date().getFullYear())
    const [chosenDate, setChosenDate] = useState<Date>(new Date())

    const language = marketService.getCurrentLanguage()

    useEffect(() => {
      setChosenDate(new Date(chosenYear, chosenMonth))
    }, [chosenMonth, chosenYear])

    return (
      <div
        style={containerStyle}
        ref={ref as RefObject<HTMLDivElement>}
        className={`Calendar__container${containerClassName ? ` ${containerClassName}` : ''}`}
      >
        <div className="Calendar__topActions">
          <Select
            label={t('calendar.month')}
            className="Calendar__topActions__monthSelector"
            value={chosenMonth}
            onChange={monthValue => {
              setChosenMonth(monthValue)
            }}
            options={months.map((month, index) => {
              return {
                key: String(index),
                label: t(month),
                value: index,
              }
            })}
          />
          <Select
            label={t('calendar.year')}
            value={chosenYear}
            onChange={yearValue => {
              setChosenYear(yearValue)
            }}
            options={years.map(year => {
              return {
                key: String(year.key),
                label: year.value,
                value: Number(year.key),
              }
            })}
          />
        </div>
        <ReactCalendar
          locale={language ?? LANGUAGE_EN}
          activeStartDate={chosenDate}
          onChange={(dateValue: Date) => {
            setValue(dateValue)
          }}
          showNavigation={false}
          showNeighboringMonth={false}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
        />
        <div className="Calendar__bottomActions">
          <button className="Calendar__cancelButton" onClick={() => setValue(null)}>
            {t('calendar.clear')}
          </button>
        </div>
      </div>
    )
  },
)

Calendar.displayName = 'Calendar'

export default Calendar
