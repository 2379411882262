import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ApiErrorTypes } from 'ogram-react'

import { useStore, useMutation } from '../../../../../../utils/mst-hooks'
import { Button } from '../../../../../../components/button/button'
import './calendar-modifications-control.scss'

function CalendarModificationsControlComponent() {
  const { scheduleModificationsAmount, clearScheduleChanges, applyScheduleChanges } = useStore().data

  const [_, requestApplySchedule] = useMutation(applyScheduleChanges, {
    filterError: [ApiErrorTypes.ClientError],
    onSuccess: () => {
      toast.success(t('all_jobs.update_success'))
      clearScheduleChanges()
    },
    onError: (err) => {
      if (err.data.errors) {
        const firstError = Object.values(err.data.errors)[0][0]
        toast.error(firstError)
      } else {
        toast.error(err.data.message)
      }
    },
  })

  const { t } = useTranslation()

  return (
    <div className="CalendarModificationsControl">
      <Button onClick={clearScheduleChanges} className="CalendarModificationsControl__cancel">
        {t('all_jobs.cancel')}
      </Button>
      <Button
        className="CalendarModificationsControl__applyChanges"
        disabled={scheduleModificationsAmount === 0}
        onClick={() => {
          requestApplySchedule(undefined)
        }}
      >
        {t('all_jobs.save_changes_count', { count: scheduleModificationsAmount })}
      </Button>
    </div>
  )
}

export const CalendarModificationsControl = observer(CalendarModificationsControlComponent)
