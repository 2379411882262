import * as Yup from 'yup'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { UserRole } from '../../../models/account'
import { useQuery, useStore, useMutation } from '../../../utils/mst-hooks'
import { Separator } from '../../../components/app-layout/separator/separator'
import { FieldError, FormFieldError } from '../../../components/form/field-error/field-error'
import { Multiselect } from '../../../components/multiselect/multiselect'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import TextInput from '../../../components/text-input/text-input'
import { Select } from '../../../components/form/select/select'
import { Button } from '../../../components/button/button'
import { Link } from '../../../services/router'
import { routes } from '../../../routes'
import './add-user.scss'
import { Header } from '../../../components/app-layout'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

const roleOptions = [
  { key: String(UserRole.Admin), label: 'Admin', value: 'Admin' },
  { key: String(UserRole.Manager), label: 'Manager', value: 'Manager' },
  { key: String(UserRole.Finance), label: 'Finance', value: 'Finance' },
]

type Props = {
  id?: string
  onSubmit?: () => void
}

export const AddUser = withErrorBoundary(
  observer(({ id, onSubmit }: Props) => {
    const {
      locationsDataView,
      getLocations,
      addNewContact,
      updateContact,
      deleteContact,
      getContactById,
      getLocationNameById,
    } = useStore().data
    const { isLoading: locationsLoading } = useQuery(getLocations)

    const locationOptions =
      locationsDataView.length > 0
        ? locationsDataView.map(location => ({ key: location.id, name: location.name }))
        : null

    const handleMutationSuccess = () => {
      if (onSubmit) {
        onSubmit()
      } else {
        window.history.back()
      }
    }

    const [requestNewContactResult, requestNewContact] = useMutation(addNewContact, {
      filterError: [ApiErrorTypes.ClientError],
      onSuccess: handleMutationSuccess,
    })
    const [requestUpdateContactResult, requestUpdateContact] = useMutation(updateContact, {
      filterError: [ApiErrorTypes.ClientError],
      onSuccess: handleMutationSuccess,
    })
    const [, requestContactDeletion] = useMutation(deleteContact, {
      filterError: [ApiErrorTypes.ClientError],
      onSuccess: handleMutationSuccess,
      onError: err => {
        alert(err.message)
      },
    })

    const { t } = useTranslation()

    const handleDeletePressed = () => {
      const agreedToDelete = confirm(t('user_management.confirm_deletion_question'))
      if (agreedToDelete) {
        requestContactDeletion(Number(id))
      }
    }

    const existingContact = typeof id === 'string' ? getContactById(Number(id)) : null
    const deletingExistingUserDisabled = existingContact?.roles.includes(UserRole.Owner)
    const errors = requestNewContactResult.error?.data.errors ?? requestUpdateContactResult.error?.data.errors

    return (
      <div className="AddUser">
        {locationsLoading || requestNewContactResult.isLoading || requestUpdateContactResult.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Header title={t('user_management.add_new_user')} showBack />
            <Separator.Vertical height={15} />
            <div className="AddUser__container">
              <Formik
                initialValues={{
                  first_name: existingContact?.first_name ?? '',
                  last_name: existingContact?.last_name ?? '',
                  phone: existingContact?.phone ?? '',
                  email: existingContact?.email ?? '',
                  roles: existingContact ? UserRole[existingContact.roles[0]] : roleOptions[0].value,
                  client_locations: existingContact?.client_locations?.length
                    ? existingContact.client_locations.map(locationId => ({
                        key: locationId,
                        name: getLocationNameById(String(locationId)) as string,
                      }))
                    : [],
                }}
                onSubmit={values => {
                  const formValues = Object.entries(values).reduce(
                    (accum, [fieldKey, fieldValue]) => {
                      if (fieldKey === 'roles') {
                        accum[`roles[0]`] = Number(roleOptions.find(role => role.value === values.roles)?.key)
                      } else if (fieldKey === 'client_locations') {
                        values.client_locations.forEach(({ key }, index) => {
                          accum[`client_locations[${index}]`] = key
                        })
                      } else {
                        accum[fieldKey] = fieldValue
                      }
                      return accum
                    },
                    {} as Record<string, unknown>,
                  )
                  if (id) {
                    requestUpdateContact({ ...formValues, id })
                  } else {
                    requestNewContact(formValues)
                  }
                }}
                validationSchema={Yup.object().shape({
                  first_name: Yup.string().required(t('user_management.enter_first_name')),
                  last_name: Yup.string().required(t('user_management.enter_last_name')),
                  phone: Yup.string().required(t('user_management.enter_phone')),
                  email: Yup.string()
                    .email(t('validation_messages.email_invalid'))
                    .required(t('user_management.enter_email')),
                  roles: Yup.string().required(t('user_management.choose_role')),
                  client_locations: Yup.array()
                    .of(
                      Yup.object().shape({
                        key: Yup.number(),
                        name: Yup.string(),
                      }),
                    )
                    .required(t('user_management.choose_location'))
                    .typeError(t('user_management.choose_location')),
                })}
              >
                {formikProps => (
                  <Form noValidate className="AddUser__container__form">
                    <FieldError errors={errors?.first_name}>
                      <TextInput
                        formikProps={formikProps}
                        label={t('user_management.first_name')}
                        formikKey="first_name"
                        disabled={Boolean(id)}
                      />
                    </FieldError>
                    <Separator.Vertical height={20} />
                    <FieldError errors={errors?.last_name}>
                      <TextInput
                        formikProps={formikProps}
                        label={t('user_management.last_name')}
                        formikKey="last_name"
                        disabled={Boolean(id)}
                      />
                    </FieldError>
                    <Separator.Vertical height={20} />
                    <FieldError errors={errors?.phone}>
                      <TextInput
                        formikProps={formikProps}
                        label={t('user_management.phone')}
                        formikKey="phone"
                        type="tel"
                      />
                    </FieldError>
                    <Separator.Vertical height={20} />
                    <FieldError errors={errors?.email}>
                      <TextInput
                        formikProps={formikProps}
                        label={t('user_management.email')}
                        formikKey="email"
                        type="email"
                      />
                    </FieldError>
                    {errors?.email && (
                      <Link
                        route={routes.inviteUser}
                        params={{ email: formikProps.values.email }}
                        className="AddUser__inviteButton"
                      >
                        {t('user_management.invite_user_exclamation')}
                      </Link>
                    )}
                    <Separator.Vertical height={20} />
                    <FieldError errors={errors?.roles}>
                      <Select
                        label={t('user_management.job_role')}
                        value={formikProps.getFieldProps('roles').value}
                        onChange={value => {
                          formikProps.setFieldValue('roles', value)
                        }}
                        options={roleOptions}
                      />
                    </FieldError>
                    <Separator.Vertical height={20} />
                    {['Manager', 'Admin'].includes(formikProps.values.roles) && (
                      <>
                        <FieldError
                          errors={(formikProps.errors.client_locations as FormFieldError) ?? errors?.client_locations}
                        >
                          <Multiselect
                            placeholder={t('user_management.location')}
                            options={locationOptions ?? []}
                            selectedOptions={formikProps.getFieldProps('client_locations').value}
                            onSelect={list => {
                              formikProps.setFieldValue('client_locations', list)
                            }}
                            onDeselect={list => {
                              formikProps.setFieldValue('client_locations', list)
                            }}
                          />
                        </FieldError>
                        <Separator.Vertical height={34} />
                      </>
                    )}
                    <div className="AddUser__container__form__buttons">
                      <Button
                        type="submit"
                        title={t('user_management.save')}
                        className="AddUser__container__form__buttons__button submit"
                      />
                      {existingContact && (
                        <>
                          <Separator.Horizontal width={10} />
                          <Button
                            title={t('user_management.delete')}
                            className={`AddUser__container__form__buttons__button delete ${
                              deletingExistingUserDisabled ? 'disabled' : ''
                            }`}
                            type="button"
                            disabled={deletingExistingUserDisabled}
                            onClick={handleDeletePressed}
                          />
                        </>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <Separator.Vertical height={23} />
          </>
        )}
      </div>
    )
  }),
)
