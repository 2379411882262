import { flow, types, SnapshotIn, SnapshotOut } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const WorkRequirementModel = types.model('WorkRequirement', {
  id: types.identifierNumber,
  name: types.string,
  is_default: types.number,
})

export const workRequirementsActions = (self: DataStore) => ({
  getWorkRequirements: flow(function* (workCategoryId: number) {
    const workRequirements: WorkRequirementSnapshot[] = yield self.request(
      'get',
      `client/work-requirements?work_category_id=${workCategoryId}`,
    )
    self.workCategoryRequirements.set(String(workCategoryId), workRequirements)
    return self.workCategoryRequirements.get(String(workCategoryId))
  }),

  addRequirement: flow(function* (params: { workCategoryId: number; name: string }) {
    yield self.request('post', 'client/work-requirements', {
      work_category_id: params.workCategoryId,
      name: params.name,
    })
  }),
})

export const workRequirementsViews = (self: DataStore) => ({
  getWorkCategoryRequirements(workCategoryId: number) {
    return self.workCategoryRequirements.get(String(workCategoryId))
  },

  getWorkCategoryRequirementsNames(workCategoryId: number, ids: number[]) {
    return self.workCategoryRequirements
      .get(String(workCategoryId))
      ?.filter((item) => ids.includes(item.id))
      .map((item) => item.name)
  },
})

export type WorkRequirement = SnapshotIn<typeof WorkRequirementModel>
export type WorkRequirementSnapshot = SnapshotOut<typeof WorkRequirementModel>
