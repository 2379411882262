import { Fragment } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'

import { Card } from '../card/card'
import { images } from '../../assets'
import { routes } from '../../../../routes'
import { PaymentStatus } from '../../../../models/payment'
import { Separator } from '../../../../components/app-layout'
import { OrderState } from '../../../../models/order/list-item-orders'
import { useQuery, useStore } from '../../../../utils/mst-hooks'
import { useRedirect } from '../../../../services/router'
import { withErrorBoundary } from '../../../../components/error/with-error-boundary'

export const Statistics = withErrorBoundary(
  observer(() => {
    const { statistics, getStatistics, companyDetails } = useStore().data

    const { isLoading: statisticsLoading } = useQuery(getStatistics)

    const redirect = useRedirect()
    const { t } = useTranslation()

    const isAffiliate = Boolean(companyDetails?.is_affiliate)

    return statisticsLoading ? null : (
      <div className="Home__cards-container">
        <Fragment>
          <Card
            amount={statistics?.pending_payments_count ?? 0}
            backgroundColor="rgba(234, 111, 126, 0.1)"
            color="rgb(234, 111, 126)"
            image={images.pendingPaymentsImg}
            title={t('home.pending_payments')}
            onPress={() => {
              redirect(routes.paymentList, { paymentStatus: String(PaymentStatus.Pending) })
            }}
          />
          <Separator.Horizontal width={15} />
          <Card
            amount={statistics?.shifts_need_confirm_count ?? 0}
            backgroundColor="rgba(252, 179, 92, 0.1)"
            color="rgb(252, 179, 92)"
            image={images.shiftsAttendanceImg}
            title={t('home.shifts_attendance_review')}
            onPress={() => {
              redirect(routes.attendanceShiftsList, {})
            }}
          />
          <Card
            amount={statistics?.orders_need_confirm_count ?? 0}
            backgroundColor="rgba(71, 117, 245, 0.1)"
            color="rgb(71, 117, 245)"
            image={images.ordersToConfirmImg}
            title={t('home.orders_to_confirm')}
            onPress={() => {
              redirect(routes.orders, { ordersState: OrderState.Unconfirmed })
            }}
          />
          <Separator.Horizontal width={15} />
          <Card
            amount={statistics?.pending_ratings_count ?? 0}
            backgroundColor="rgba(70, 78, 95, 0.1)"
            color="rgb(70, 78, 95)"
            image={images.ratingRequestsImg}
            title={t('home.rating_requests')}
            onPress={() => {
              redirect(routes.jobRatingRequests, {})
            }}
          />
          <Card
            amount={statistics?.unfulfilled_jobs_count ?? 0}
            backgroundColor="rgba(94, 170, 111, 0.1)"
            color="rgb(94, 170, 111)"
            image={images.applicantsReviewImg}
            title={t('home.applicant_review')}
            onPress={() => {
              redirect(routes.applicantsReview, {})
            }}
          />
          <Separator.Horizontal width={15} />
          {isAffiliate && (
            <>
              <Card
                amount={statistics?.referrals_count ?? 0}
                backgroundColor="rgba(119, 103, 228, 0.1)"
                color="rgb(119, 103, 228)"
                image={images.referralsImg}
                title={t('common_affiliate.my_referrals')}
                onPress={() => {
                  redirect(routes.affiliateDashboard, {})
                }}
              />
            </>
          )}
          <div className="LastCardSeparator"></div>
        </Fragment>
      </div>
    )
  }),
  { [ApiErrorTypes.ServerError]: 'home.error_failed_to_load_statistics' },
)
