import { createRoute } from './services/router'
import { DraftJobFields } from './models-ui/order/store/job-fields/fields'

export const routes = {
  index: createRoute('/'),
  signUp: createRoute<{ redirect?: 'index' | 'setJobCategory' }>('/sign-up'),
  signUpWithOrder: createRoute('/start'),
  signUpWithOrderForm: createRoute('/start/form'),
  forgotPassword: createRoute('/forgot-password'),
  resetPassword: createRoute('/reset-password'),
  changePassword: createRoute('/change-password'),
  login: createRoute<{ url?: string }>('/login'),
  verifyEmail: createRoute<{ email: string }>('/verifyemail'),

  allJobs: createRoute<{ date?: string }>('/all-jobs/:date'),
  pastWorkers: createRoute('/staff/past'),
  applicantsReview: createRoute('/jobs/applicants'),
  users: createRoute('/users'),
  unconfirmedUsers: createRoute('/users/unconfirmed'),
  createUser: createRoute<{ id?: string }>('/users/create'),
  inviteUser: createRoute<{ email?: string }>('/users/invite'),

  attendanceManagers: createRoute('/profile/attendance-managers'),
  addAttendanceManagers: createRoute('/attendance-managers/add'),
  editAttendanceManagers: createRoute<{ id: string }>('/profile/attendance-managers/edit/:id'),

  editProfile: createRoute('/profile/edit'),
  editCompany: createRoute('/profile/edit-company'),

  setPasswordToHire: createRoute('/onboarding/hire-now'),
  setCompanyDetailsToHire: createRoute('/onboarding/hire-now/company-details'),
  setPasswordToManageSchedule: createRoute('/onboarding/manage-schedule'),
  setCompanyDetailsToManageSchedule: createRoute('/onboarding/manage-schedule/company-details'),

  locations: createRoute('/locations'),
  editLocations: createRoute<{ id: string }>('/locations/edit/:id'),
  addLocations: createRoute('/locations/add'),

  paymentMethods: createRoute('/profile/payment-methods'),
  editPaymentMethod: createRoute<{ id: string }>('/profile/payment-methods/edit/:id'),
  addPaymentMethod: createRoute('/profile/payment-methods/add'),

  invoices: createRoute('/profile/invoices'),
  invoice: createRoute<{ id: string }>('/profile/invoices/:id'),
  invoiceOgrammer: createRoute<{ invoiceItemId: string; ogrammerId: string; name: string }>(
    '/profile/invoices/:invoiceItemId/:ogrammerId',
  ),

  preferences: createRoute('/profile/preferences'),

  orderDraft: createRoute<{ orderId: string; title?: string }>('/orders/create'),
  extendedOrderDraft: createRoute<{ orderId: string }>('/orders/extend'),
  setJobCategory: createRoute<{ orderId?: string; draftJobId?: string; stepNumber?: string }>(
    '/orders/create/jobs/create/category',
  ),
  jobDraftDetails: createRoute<{ orderId?: string; draftJobId: string; stepNumber?: string; isExtension?: string }>(
    '/orders/create/jobs/create/details',
  ),
  jobDraftDetailsPopupField: createRoute<{
    orderId?: string
    draftJobId: string
    popupField?: DraftJobFields | null
    stepNumber?: string
    isExtension?: string
  }>('/orders/create/jobs/create/details/:popupField'),
  orderDraftSummary: createRoute<{ orderId: string; showAmend: string }>('/order/:orderId/summary'),
  completeCompanyDetailsForPaymentTerms: createRoute<{ orderId: string; showAmend: string }>(
    '/complete-company-details',
  ),
  orderRequestSuccess: createRoute<{ orderId: string }>('/order/:orderId/summary/requested'),
  orderConfirmation: createRoute<{ orderId: string }>('/order/:orderId/confirm'),
  orderCreateSuccess: createRoute('/order/create/:orderId/success'),
  orderDuplicate: createRoute<{ orderId: string }>('/order/:orderId/duplicate'),
  orderDuplicateChooseJobs: createRoute<{ orderId: string }>('/order/:orderId/duplicate/choose-jobs'),

  attendanceShiftsList: createRoute('/attendance-shifts'),
  attendanceShiftDetails: createRoute('/shift-details'),
  attendanceQR: createRoute<{ spId: string }>('/qr-attendance'),
  completeRegistration: createRoute('/complete-registration'),
  signSLA: createRoute('/sign-sla'),
  onboardingFinish: createRoute<{ navigateToScreenName: string }>('/onboarding-finish'),
  jobOGCherryPicker: createRoute<{ jobId: string; shiftId?: string }>('/jobs/:jobId/applicants'),
  applicantDetails: createRoute<{ jobId: string; applicantId: string; applicantName: string }>(
    '/jobs/:jobId/applicants/:applicantId',
  ),
  jobRatingRequests: createRoute('/job-rating-requests'),
  jobRatingDetails: createRoute<{ id: number }>('/job-rating-requests/:id'),
  knowledgeCenter: createRoute('/knowledge-center'),
  orders: createRoute<{ ordersState?: string }>('/orders-list/:ordersState'),
  confirmClientInvitation: createRoute<{ clientId: string }>('/confirm-client-invitation/:clientId'),

  paymentList: createRoute<{ paymentStatus?: string }>('/payments-list/:paymentStatus'),
  payWithCard: createRoute<{ paymentId: string; paymentOptions?: string }>('/payments/:paymentId/card/pay'),
  cardPaymentStatus: createRoute<{ paymentId: string; paymentOptions?: string }>('/payments/:paymentId/status'),
  paymentMethodsList: createRoute<{ paymentId: string; paymentOptions?: string }>(
    '/payments/:paymentId/payment-methods',
  ),
  payWithSavedCard: createRoute<{ paymentId: string; cardId: string; paymentOptions?: string }>(
    '/payments/:paymentId/saved-card/pay',
  ),
  paymentDetails: createRoute<{ paymentId: string; hideBack?: number }>('/payments/:paymentId'),
  accountStatement: createRoute('/account-statement'),
  wireTransferDetails: createRoute<{ paymentId: string }>('/payments/:paymentId/wire/details'),
  wireTransferUploadSlip: createRoute<{ paymentId: string }>('/payments/:paymentId/wire/slip'),
  savedPaymentMethods: createRoute('/saved-payment-methods'),
  saveCard: createRoute('/save-card'),

  affiliateDashboard: createRoute('/affiliate/home'),
  affiliateReferralsList: createRoute('/affiliate/referrals'),
  affiliateEarnings: createRoute('/affiliate/earnings'),
}
