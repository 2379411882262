import { useMemo, Fragment } from 'react'
import { observer } from 'mobx-react'

import { useStore } from '../../../../../../utils/mst-hooks'
import { formatDateToIso8601, getWeekDates } from '../../../../../../utils/datetime-utils'
import { ScheduleItem } from './components/schedule-item'
import { RowSeparator } from './components/row-separator'
import { Column } from './components/column'
import './calendar-schedule.scss'

const MIN_ROWS = 8

function CalendarScheduleComponent({ firstWeekDate }: { firstWeekDate: Date }) {
  const { getJobsScheduleForWeek } = useStore().data

  const formatedWeekDate = formatDateToIso8601(firstWeekDate)
  const jobsScheduleForWeek = getJobsScheduleForWeek(formatedWeekDate)
  const weekDates = getWeekDates(firstWeekDate)

  const emptyColumns = useMemo(() => {
    const jobs = jobsScheduleForWeek.length
    const assignedSps = jobsScheduleForWeek.reduce((spsCount, jobScheduleItem) => {
      return spsCount + jobScheduleItem.assigned_sps.size
    }, 0)
    const totalRows = jobs + assignedSps
    const diff = MIN_ROWS - totalRows
    const numberOfEmptyColumns = diff > 0 ? diff : 0
    return new Array(numberOfEmptyColumns).fill(null) as null[]
  }, [jobsScheduleForWeek])

  return (
    <div className="CalendarSchedule">
      {jobsScheduleForWeek.map(jobItem => (
        <ScheduleItem
          key={jobItem.toString()}
          jobSchedule={jobItem}
          firstWeekDate={firstWeekDate}
          weekDates={weekDates}
        />
      ))}
      {emptyColumns.map((_, index) => (
        <div className="ScheduleItem" key={index}>
          <div className="ScheduleItem__row">
            <div className="JobColumn" />
            {weekDates.map((__, i) => (
              <Fragment key={i}>
                <RowSeparator />
                <Column>
                  <></>
                </Column>
              </Fragment>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export const CalendarSchedule = observer(CalendarScheduleComponent)
