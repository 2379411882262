import { useState, useMemo } from 'react'
import { observer } from 'mobx-react'

import { Schedule, ScheduleSp } from '../../../../../../../models/schedule/types'
import { Collapsible } from '../../../../../../../components/collapsible/collapsible'
import { JobColumn } from './job-column'
import { SpColumn } from './sp-column'
import { Cells } from './cell'
import { ShiftCellComponent } from './shift-cell'
import { SpCell } from './sp-cell'
import './schedule-item.scss'

type ScheduleItemProps = {
  jobSchedule: Schedule
  firstWeekDate: Date
  weekDates: Date[]
}

function ScheduleItemComponent({ jobSchedule, firstWeekDate, weekDates }: ScheduleItemProps) {
  const [collapsed, setCollapsed] = useState(false)

  /**
   * Gets rows for the job.
   * If no OGs are assigned yet returns empty rows equal (row amount == job quantity)
   * If amount of assigned OGs is less than job quantity returns OG rows + remaining empty rows
   * If amount of assigned OGs is equal to job quantity returns OG rows (row amount == job quantity)
   * If amount of assigned OGs is bigger than job quantity returns OG rows (row amount == amount of assigned OGs)
   */
  const rows = useMemo(() => {
    const { spList, quantity } = jobSchedule
    const hasMoreSpsThanQuantity = spList.length > quantity
    const rowList: (ScheduleSp | null)[] = new Array(hasMoreSpsThanQuantity ? spList.length : quantity)
      .fill(null)
      .map((_, index) => {
        if (index < spList.length) {
          return spList[index]
        } else {
          return null
        }
      })
    return rowList
  }, [])

  return (
    <div className="ScheduleItem">
      <div className="ScheduleItem__row">
        <JobColumn
          workCategory={jobSchedule.work_category_name}
          quantity={jobSchedule.quantity}
          location={jobSchedule.location_name}
          orderId={jobSchedule.order_id}
          setCollapsed={() => {
            setCollapsed(prevState => !prevState)
          }}
        />
        <Cells
          weekDates={weekDates}
          firstWeekDate={firstWeekDate}
          jobSchedule={jobSchedule}
          renderCell={ShiftCellComponent}
        />
      </div>
      <Collapsible collapsed={collapsed} maxHeight={92 * rows.length}>
        {rows.map((spItem, index) => {
          return (
            <div className={`ScheduleItem__row ${index % 2 === 0 ? 'odd' : ''}`} key={index}>
              {spItem ? (
                <SpColumn imageUrl={spItem.image} name={spItem.name} spId={spItem.id} jobId={jobSchedule.job_id} />
              ) : (
                <div className="SpColumn" />
              )}
              <Cells
                weekDates={weekDates}
                firstWeekDate={firstWeekDate}
                jobSchedule={jobSchedule}
                renderCell={({ shift, dateShift }) => (
                  <SpCell
                    shift={shift}
                    dateShift={dateShift}
                    spId={spItem ? spItem.id : -1} // will render empty cell for non-real id
                    jobSchedule={jobSchedule}
                  />
                )}
              />
            </div>
          )
        })}
      </Collapsible>
    </div>
  )
}

export const ScheduleItem = observer(ScheduleItemComponent)
