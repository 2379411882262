import { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import shiftTimes from '../../assets/exclamination-circle-icon.svg'
import OrderSummaryShiftsModal from '../order-summary-shifts-modal/order-summary-shifts-modal'
import './order-summary-jobs-card.scss'
import { PastSPSnapshot } from '../../../../models/past-workers'
import { InvitedSP } from '../../../../components/invited-sp/invited-sp'

export interface Shift {
  start_time: number
  end_time: number
}

type OrderSummaryJobsCardProps = {
  work_category_name: string
  start_date: Moment
  end_date: Moment
  hours: number
  hourly_rate: number | null
  quantity: number
  currency: string | null
  transportation_costs: number
  shifts: Shift[]
  underReview: boolean
  showRate?: boolean
  showTransportations?: boolean
  invitedSPs: PastSPSnapshot[]
}

const OrderSummaryJobsCard = ({
  work_category_name,
  start_date,
  end_date,
  hours,
  hourly_rate,
  transportation_costs,
  quantity,
  currency,
  shifts,
  underReview,
  showRate = true,
  showTransportations = true,
  invitedSPs,
}: OrderSummaryJobsCardProps) => {
  const [shiftsModalIsOpen, setShiftsModalIsOpen] = useState(false)
  const shiftHoursPerDay = hours / shifts.length / quantity
  const shiftHoursPerDayLabel = Number.isInteger(shiftHoursPerDay)
    ? String(shiftHoursPerDay)
    : shiftHoursPerDay.toFixed(2)
  const openShiftsModal = () => setShiftsModalIsOpen(true)

  const { t } = useTranslation()

  return (
    <div className="OrderSummaryJobsCard">
      <div className="OrderSummaryJobsCard__name">
        <h3>{work_category_name}</h3>
        <span>({quantity})</span>
      </div>
      <div className="OrderSummaryJobsCard__row">
        <p className="OrderSummaryJobsCard__label">{t('order.dates')}</p>
        <p className="OrderSummaryJobsCard__data">
          {start_date.format('MMM DD')} - {end_date.format('MMM DD')}
          <span>{t('order.days_count', { count: shifts.length })}</span>
          <img src={shiftTimes} onClick={openShiftsModal} />
        </p>
      </div>
      <div className="OrderSummaryJobsCard__row">
        <p className="OrderSummaryJobsCard__label">{t('order.hours')}</p>
        <p className="OrderSummaryJobsCard__data">
          {t('order.hours_amount', { hours })}{' '}
          <span>{t('order.hours_per_day', { hoursPerDay: shiftHoursPerDayLabel })}</span>
        </p>
      </div>
      {showRate && (
        <div className="OrderSummaryJobsCard__row">
          <p className="OrderSummaryJobsCard__label">{t('order.rate')}</p>
          {underReview ? (
            <div className="OrderSummaryJobsCard__reviewContainer">
              <span className="OrderSummaryJobsCard__reviewContainer__label">{t('order.under_review')}</span>
            </div>
          ) : (
            <p className="OrderSummaryJobsCard__data">{t('order.hour_rate', { currency, hourRate: hourly_rate })}</p>
          )}
        </div>
      )}
      {showTransportations && (
        <div className="OrderSummaryJobsCard__row">
          <p className="OrderSummaryJobsCard__label">{t('order.transportations')}</p>
          {underReview ? (
            <div className="OrderSummaryJobsCard__reviewContainer">
              <span className="OrderSummaryJobsCard__reviewContainer__label">{t('order.under_review')}</span>
            </div>
          ) : (
            <p className="OrderSummaryJobsCard__data">
              {t('order.transportation_cost', { currency, cost: transportation_costs })}
            </p>
          )}
        </div>
      )}
      {invitedSPs.length && (
        <div className="OrderSummaryJobsCard__row OrderSummaryJobsCard__row--vertical">
          <p className="OrderSummaryJobsCard__label">{t('invited_sps.invited_sps')}</p>
          <div>
            {invitedSPs.map(sp => (
              <InvitedSP
                key={sp.id}
                disabled={false}
                withCheckbox={false}
                sp={sp}
                selected={true}
                showDesignation={false}
              />
            ))}
          </div>
        </div>
      )}
      <OrderSummaryShiftsModal
        modalIsOpen={shiftsModalIsOpen}
        closeModal={() => setShiftsModalIsOpen(false)}
        start_date={start_date}
        shifts={shifts}
        end_date={end_date}
        work_category_name={work_category_name}
      />
    </div>
  )
}

export default OrderSummaryJobsCard
