import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useStore, useDelayedQuery } from '../../../../../utils/mst-hooks'
import { LoadingSpinner } from '../../../../loading-spinner/loading-spinner'
import { Select } from '../../../../form/select/select'
import { Separator } from '../../../../app-layout/separator/separator'
import { Button } from '../../../../button/button'
import { OgramModal } from '../../../../ogram-modal/ogram-modal'
import { AddUser } from '../../../../../pages/users-managment/add-user/add-user'
import crossImg from '../../../../assets/cross-icon-black.svg'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { JobDraftFieldError } from '../error/error'
import './manager-contact.scss'

export const JobDraftPOC = observer(({ job }: { job: DraftJobInstance }) => {
  const [addPocModalVisible, setAddPocModalVisible] = useState(false)

  const { pointsOfContact, getPointsOfContact, getContactById } = useStore().data

  const { query: pocQuery, enableQuery } = useDelayedQuery(
    () => getPointsOfContact({ locationId: job.location.value?.id }),
    {
      onSuccess: pocList => {
        if (!job.manager.id) {
          job.manager.set(pocList[0].id)
        }
      },
    },
  )

  useEffect(() => {
    enableQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job.location.value?.id])

  const { t } = useTranslation()

  const pocOptions = pointsOfContact?.length
    ? pointsOfContact.map(poc => {
        const name = `${poc.first_name} ${poc.last_name}`
        return { key: name, label: name, value: poc.id }
      })
    : null

  const poc = job.manager.id ? getContactById(job.manager.id) : null

  if (!job.location.value && !job.manager.id) {
    return (
      <div className="JobDraftPOC">
        <span className="Popup__title">{t('job_draft_details.poc_title')}</span>
        <span className="Popup__subtitle">{t('job_draft_details.select_location_first')}</span>
      </div>
    )
  }

  return (
    <div className="JobDraftPOC">
      <span className="Popup__title">{t('job_draft_details.poc_title')}</span>
      <span className="Popup__subtitle">{t('job_draft_details.poc_subtitle')}</span>
      {pocQuery.isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Select
            label={t('job_draft_details.point_of_contact')}
            value={poc?.id}
            onChange={id => {
              job.manager.set(id)
            }}
            options={pocOptions ?? []}
            className={`JobDraftPOC__pocSelect ${job.manager.hasError ? 'error' : ''}`}
          />
          <JobDraftFieldError error={job.manager.error} />
          <Separator.Vertical height={35} />
          <Button
            onClick={() => {
              setAddPocModalVisible(true)
            }}
          >
            <span>{t('job_draft_details.add_poc')}</span>
          </Button>
          <OgramModal
            modalVisible={addPocModalVisible}
            onClose={() => {
              setAddPocModalVisible(false)
            }}
            className="JobDraftPOC__modal"
          >
            <div className="JobDraftPOC__modal__header">
              <div />
              <p className="JobDraftPOC__modal__header__modalTitle">{t('job_draft_details.add_poc')}</p>
              <img
                src={crossImg}
                onClick={() => {
                  setAddPocModalVisible(false)
                }}
                className="JobDraftPOC__modal__header__modalCloseImg"
              />
            </div>
            <AddUser
              onSubmit={() => {
                setAddPocModalVisible(false)
                pocQuery.reload()
              }}
            />
          </OgramModal>
        </>
      )}
    </div>
  )
})
