import { useTranslation } from 'react-i18next'

import { Status, StatusType } from './status'
import { ShiftStatus, ScheduleShiftSnapshot } from '../../../../../../../models/schedule/types'
import { AssignShiftTools } from './assign-shift-tools'
import './sp-empty.scss'

type SpEmptyProps = {
  shift: ScheduleShiftSnapshot
  jobId: number
}

export function SpEmpty({ shift, jobId }: SpEmptyProps) {
  const { t } = useTranslation()

  if (shift.fulfilled_count === shift.required_quantity) {
    return null
  }

  return (
    <div className="SpEmpty">
      <div className="SpEmpty__top">
        <span className="SpEmpty__top__label">{t('all_jobs.assigned_to')}</span>
        <span className="SpEmpty__top__value">{t('all_jobs.no_one')}</span>
      </div>
      <div className="SpEmpty__bottom">
        <Status status={StatusType.spEmpty} title={t('all_jobs.empty_slot')} />
        {shift.status === ShiftStatus.Started ||
          (shift.status === ShiftStatus.Upcoming && <AssignShiftTools jobId={jobId} shiftId={shift.id} />)}
      </div>
    </div>
  )
}
