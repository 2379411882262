import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FormikProps } from 'formik/dist/types'

import { Separator } from '../../../../../../components/app-layout'
import { FieldError } from '../../../../../../components/form/field-error/field-error'
import { Input } from '../../../../../../components/form/input/input'
import { BasicDataFormProps } from '../../../types'
import { Header } from '../../../../components/header/header'
import { Link } from '../../../../../../services/router'
import { routes } from '../../../../../../routes'
import { getCurrentMarketConfig } from '../../../../../../services/market-service'
import '../form.scss'

type UIProps = {
  firstName: string
  lastName: string
  companyName: string
  handleFirstNameChange: (value: string) => void
  handleLastNameChange: (value: string) => void
  handleCompanyNameChange: (value: string) => void
  innerRef: React.Ref<FormikProps<BasicDataFormProps>>
  firstNameErrors: string[] | string | undefined
  lastNameErrors: string[] | string | undefined
  companyNameErrors: string[] | string | undefined
}

export const BasicDataForm = ({
  firstName,
  lastName,
  companyName,
  handleFirstNameChange,
  handleLastNameChange,
  handleCompanyNameChange,
  innerRef,
  firstNameErrors,
  lastNameErrors,
  companyNameErrors,
}: UIProps) => {
  const { t } = useTranslation()
  const allowOnlyCorporate = getCurrentMarketConfig().registration.allowOnlyCorporate

  return (
    <Formik
      innerRef={innerRef}
      initialValues={{
        firstName,
        lastName,
        companyName,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required(t('sign_up.first_name_required')),
        lastName: Yup.string().required(t('sign_up.last_name_required')),
        ...(allowOnlyCorporate ? { companyName: Yup.string().required(t('sign_up.company_name_required')) } : {}),
      })}
      enableReinitialize
      onSubmit={values => {}}
      validateOnChange={false}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Fragment>
            <Header title={t('sign_up.create_new_order_title')} />
            <Separator.Vertical height={45} />
            <span className="SignUp__title">{t('sign_up.contact_details_title')}</span>
            <Separator.Vertical height={1} />
            <span className="SignUp__subtitle">{t('sign_up.contact_details_subtitle')}</span>
            <Separator.Vertical height={27} />
            <div className="SignUp__row">
              <FieldError errors={errors.firstName ?? firstNameErrors}>
                <Input
                  label={t('sign_up.first_name')}
                  value={values.firstName}
                  handleChange={value => {
                    setFieldValue('firstName', value)
                    handleFirstNameChange(value)
                  }}
                />
              </FieldError>
              <Separator.Horizontal width={7} />
              <FieldError errors={errors.lastName ?? lastNameErrors}>
                <Input
                  label={t('sign_up.last_name')}
                  value={values.lastName}
                  handleChange={value => {
                    setFieldValue('lastName', value)
                    handleLastNameChange(value)
                  }}
                />
              </FieldError>
            </div>
            <Separator.Vertical height={16} />
            <FieldError errors={errors.companyName ?? companyNameErrors}>
              <Input
                label={allowOnlyCorporate ? t('sign_up.company_name') : t('sign_up.company_name_optional')}
                value={values.companyName}
                handleChange={value => {
                  setFieldValue('companyName', value)
                  handleCompanyNameChange(value)
                }}
              />
            </FieldError>
            <div className="SignUp__already_registered_container">
              <span className="SignUp__already_registered_container__text">
                {t('sign_up.already_registered')}{' '}
                <Link route={routes.login} params={{}} className="SignUp__already_registered_container__link">
                  {t('sign_up.sign_in')}
                </Link>
              </span>
            </div>
            <Separator.Vertical height={27} />
          </Fragment>
        )
      }}
    </Formik>
  )
}
