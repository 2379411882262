import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../app-layout/separator/separator'
import { Button } from '../../../../button/button'
import './manual-selection.scss'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { AssignmentType } from '../../../../../models-ui/order/store/job-fields/manual-tools'
import { JobDraftFieldError } from '../error/error'

export const JobDraftManualSelection = observer(({ job }: { job: DraftJobInstance }) => {
  const { t } = useTranslation()

  return (
    <div className="JobDraftManualSelection">
      <span className="Popup__title">{t('job_draft_details.manual_selectinon_title')}</span>
      <span className="JobDraftManualSelection__description">{t('job_draft_details.manual_selectinon_subtitle')}</span>
      <div className="JobDraftManualSelection__innerContainer">
        <Button
          title={t('job_draft_details.manual_selectinon_yes')}
          onClick={() => {
            job.manualSelection.setManualSelection(false)
          }}
          className={`JobDraftManualSelection__innerContainer__button ${
            job.manualSelection.manual_selection === AssignmentType.autoAccept ? 'active' : ''
          } ${job.manualSelection.hasManualSelectionError ? 'error' : ''}`}
        />
        <Separator.Vertical height={10} />
        <Button
          title={t('job_draft_details.manual_selectinon_no')}
          onClick={() => {
            job.manualSelection.setManualSelection(true)
          }}
          className={`JobDraftManualSelection__innerContainer__button ${
            job.manualSelection.manual_selection === AssignmentType.preApproval ? 'active' : ''
          } ${job.manualSelection.hasManualSelectionError ? 'error' : ''}`}
        />
        <JobDraftFieldError error={job.manualSelection.manual_selection_error} />
      </div>
    </div>
  )
})
