import { useTranslation } from 'react-i18next'

import { useRedirect } from '../../services/router'
import { useStore } from '../../utils/mst-hooks'
import { remove } from '../../services/storage'
import { trackingService } from '../../services/tracking-service'
import { routes } from '../../routes'
import accessDeniedErrorImage from '../assets/not-authorized-error.svg'
import { ROOT_STATE_STORAGE_KEY } from '../../models/utils/setup-root-store'
import { DRAFT_JOBS_STORE_KEY } from '../../models-ui/order/store'
import './not-authorized-error.scss'

interface NotAuthorizedErrorProps {
  resetError: () => void
}

const NotAuthorizedError = ({ resetError }: NotAuthorizedErrorProps) => {
  const navigate = useRedirect()
  const { logoutLocal } = useStore().data

  const { t } = useTranslation()

  return (
    <div className="NotAuthorizedError">
      <div className="NotAuthorizedError__container">
        <img src={accessDeniedErrorImage} />
        <h2 className="NotAuthorizedError__modalTitle">{t('not_authorized_error.title')}</h2>
        <p>{t('not_authorized_error.description')}</p>
        <button
          onClick={() => {
            const currentURI = window.location.pathname + window.location.search + window.location.hash

            logoutLocal()
            resetError()
            navigate(routes.login, { url: currentURI })
            remove(ROOT_STATE_STORAGE_KEY)
            remove(DRAFT_JOBS_STORE_KEY)
            trackingService.resetUser()
          }}
          className="NotAuthorizedError__backButton"
        >
          {t('not_authorized_error.sign_in')}
        </button>
      </div>
    </div>
  )
}

export default NotAuthorizedError
