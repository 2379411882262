import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'
import { observer } from 'mobx-react'

import { useRedirect } from '../../../../../services/router'
import { routes } from '../../../../../routes'
import { CherryPickingApplicant } from '../../../../../models/cherry-picking-applicants'
import { useMutation, useStore } from '../../../../../utils/mst-hooks'
import CherryPickingConfirmModal from '../cherry-picking-confirm-modal/cherry-picking-confirm-modal'
import CherryPickingSuccessModal from '../success-modal/cherry-picking-success-modal'
import ErrorModal from '../../../../../components/error-modal/error-modal'
import acceptedIcon from '../../assets/accepted.svg'
import './accept-and-reject-buttons-section.scss'
import { withErrorBoundary } from '../../../../../components/error/with-error-boundary'

type AcceptAndRejectSectionProps = {
  applicant: CherryPickingApplicant
  extendOnConfirm?: () => void
  jobId: string
  shiftId?: number
}

enum ConfirmationModalTypes {
  accept = 'accept',
  reject = 'reject',
}

type ModalType = 'accept' | 'reject'

const AcceptAndRejectSection = withErrorBoundary(
  observer(({ applicant, extendOnConfirm, jobId, shiftId }: AcceptAndRejectSectionProps) => {
    const { acceptApplicantApiCall, rejectApplicantApiCall, getCherryPickingByIdView } = useStore().data
    const cherryPickingJob = getCherryPickingByIdView(jobId)
    const navigate = useRedirect()
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [modalType, setModalType] = useState<ModalType>('accept')
    const closeConfirmationModal = () => setIsConfirmationModalOpen(false)
    const [rejectApplicantQuery, rejectApplicant] = useMutation(rejectApplicantApiCall)
    const [acceptApplicantQuery, acceptApplicant] = useMutation(acceptApplicantApiCall, {
      onSuccess: () => {
        if (cherryPickingJob.shifts_count <= cherryPickingJob.shifts_fulfilled_count) {
          openSuccessModal()
        }
      },
      onError: (error) => {
        setErrorMessage(error.data.message)
        openErrorModal()
      },
      filterError: [ApiErrorTypes.ClientError],
    })
    const onAccept = () => {
      setModalType(ConfirmationModalTypes.accept)
      openConfirmationModal()
    }
    const onReject = () => {
      setModalType(ConfirmationModalTypes.reject)
      openConfirmationModal()
    }
    const openConfirmationModal = () => setIsConfirmationModalOpen(true)
    const onConfirm = () => {
      if (modalType === ConfirmationModalTypes.accept) {
        acceptApplicant({
          sp_id: String(applicant.id),
          job_id: Number(jobId),
          shift_ids: shiftId ? [shiftId] : undefined,
        })
      } else {
        rejectApplicant({ sp_id: String(applicant.id), job_id: Number(jobId) })
      }
      if (extendOnConfirm) {
        extendOnConfirm() // extra logic when confirm, more than just accept/reject
      }
      closeConfirmationModal()
    }
    const openSuccessModal = () => {
      setIsSuccessModalOpen(true)
    }
    const closeSuccessModal = () => {
      setIsSuccessModalOpen(false)
    }
    const onConfirmSuccessModal = () => {
      navigate(routes.applicantsReview, {})
    }
    const openErrorModal = () => {
      setIsErrorModalOpen(true)
    }
    const closeErrorModal = () => {
      setIsErrorModalOpen(false)
    }

    const { t } = useTranslation()

    return (
      <div className="AcceptAndRejectSection">
        {applicant.is_accepted ? (
          <button disabled className="AcceptAndRejectSection__accepted">
            <img src={acceptedIcon} /> {t('job_cherry_picking.accepted')}
          </button>
        ) : (
          <>
            {!shiftId && (
              <button
                disabled={rejectApplicantQuery.isLoading || acceptApplicantQuery.isLoading}
                className="AcceptAndRejectSection__reject"
                onClick={onReject}
              >
                {t('job_cherry_picking.reject')}
              </button>
            )}
            <button
              disabled={rejectApplicantQuery.isLoading || acceptApplicantQuery.isLoading}
              className="AcceptAndRejectSection__accept"
              onClick={onAccept}
            >
              {t('job_cherry_picking.accept')}
            </button>
          </>
        )}
        <CherryPickingConfirmModal
          applicantName={applicant.name}
          modalIsOpen={isConfirmationModalOpen}
          closeModal={closeConfirmationModal}
          onConfirm={onConfirm}
          type={modalType}
        />
        <CherryPickingSuccessModal
          modalIsOpen={isSuccessModalOpen}
          closeModal={closeSuccessModal}
          onConfirm={onConfirmSuccessModal}
        />
        <ErrorModal
          errorMessage={errorMessage}
          onConfirm={closeErrorModal}
          isModalVisible={isErrorModalOpen}
          onClose={closeErrorModal}
        />
      </div>
    )
  }),
)

export default AcceptAndRejectSection
