import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'

import confirmed from '../../../components/assets/confirmed.svg'
import { Separator } from '../../../components/app-layout/separator/separator'
import { ButtonLink } from '../../../components/button-link/button-link'
import { routes } from '../../../routes'
import './order-requested.scss'
import { OrderState } from '../../../models/order/list-item-orders'
import { StepIndicator } from '../../../components/step-indicator/step-indicator'
import { Header } from '../../../components/app-layout'
import orderNumberIcon from './assets/order_number.svg'
import jobsCountIcon from './assets/jobs_count.svg'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { useQuery, useStore } from '../../../utils/mst-hooks'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import { TextAlert } from '../../../components/text-alert/text-alert'
import { getCurrentMarketConfig } from '../../../services/market-service'
import { VERIFICATION_STATUS_APPROVED } from '../../../models/company-details'
import { StickyPanel } from '../../../components/sticky-panel/sticky-panel'
import { useRedirect } from '../../../services/router'

const stepIndicatorConfig = {
  stepsCount: 3,
  currentStepNumber: 3,
}

export const OrderRequestedPage = withErrorBoundary(
  observer(({ orderId }: { orderId: number }) => {
    const { t } = useTranslation()
    const redirect = useRedirect()

    const { getOrderSummary, getOrderIdSummary } = useStore().data

    const orderSummary = getOrderIdSummary(String(orderId))
    const orderSummaryQuery = useQuery(() => getOrderSummary({ orderId: String(orderId) }), {
      filterError: [ApiErrorTypes.ClientError],
    })

    const isCorporateDetailsRequiredAndNotVerified =
      getCurrentMarketConfig().registration.allowOnlyCorporate &&
      orderSummary?.corporate_details_verification_status !== VERIFICATION_STATUS_APPROVED

    return (
      <div className="OrderRequested">
        <Header showBack title={t('order_draft.title')} />
        <Separator.Vertical height={50} />
        {orderSummaryQuery.isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="OrderRequested__innerContainer">
            <StepIndicator
              stepsCount={stepIndicatorConfig.stepsCount}
              currentStepNumber={stepIndicatorConfig.currentStepNumber}
            />
            <Separator.Vertical height={15} />
            <h2 className="OrderRequested__innerContainer__title">{t('order_requested.title')}</h2>
            <Separator.Vertical height={20} />
            <div className="OrderRequested__horizontalStats">
              <div className="OrderRequested__horizontalStats__item">
                <img className="OrderRequested__horizontalStats__item__image" src={orderNumberIcon} />
                <span className="OrderRequested__horizontalStats__item__title">
                  {t('order.order_number', { id: orderId })}
                </span>
              </div>
              <div className="OrderRequested__horizontalStats__item">
                <img className="OrderRequested__horizontalStats__item__image" src={jobsCountIcon} />
                <span className="OrderRequested__horizontalStats__item__title">
                  {t('order_draft.jobs_count', { count: orderSummary?.jobs.length })}
                </span>
              </div>
            </div>
            {isCorporateDetailsRequiredAndNotVerified && (
              <>
                <Separator.Vertical height={14} />
                <TextAlert
                  backgroundColor="rgba(252, 179, 92, 0.2)"
                  text={t('order.need_to_review_corporate_details')}
                />
                <Separator.Vertical height={40} />
              </>
            )}
            <img src={confirmed} className="OrderRequested__innerContainer__img" />
            <Separator.Spacer flex={0.2} />
            <p className="OrderRequested__innerContainer__description">{t('order_requested.subtitle')}</p>
            <Separator.Spacer />
            {!isCorporateDetailsRequiredAndNotVerified && (
              <ButtonLink
                url={routes.orders}
                title={t('order_requested.done')}
                params={{ ordersState: OrderState.Unconfirmed }}
                className="OrderRequested__innerContainer__closeButton"
              />
            )}
            <Separator.Vertical height={40} />
            {isCorporateDetailsRequiredAndNotVerified && (
              <StickyPanel onClick={() => {}} style={{ justifyContent: 'center' }}>
                <button
                  className="OrderRequested__actionPanel__secondaryButton"
                  onClick={() => redirect(routes.index, {})}
                >
                  {t('order_requested.later')}
                </button>
                <button
                  className="OrderRequested__actionPanel__primaryButton"
                  onClick={() => redirect(routes.completeRegistration, {})}
                >
                  {t('order_requested.complete_registration')}
                </button>
              </StickyPanel>
            )}
          </div>
        )}
        <Separator.Spacer minHeight={20} />
      </div>
    )
  }),
)
