import { OnboardingContent } from '../../../page-onboarding/page-onboarding'
import { t } from '../../../../translations'
import { routes } from '../../../../routes'
import { jobRequirementsPage } from '../pages/job-requirements'

export const jobRequirementsOnboarding: OnboardingContent = {
  title: t('order_creation_onboarding.title'),
  paths: [`${routes.jobDraftDetails.path}/requirements`],
  pages: [jobRequirementsPage],
}
