import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'

import { StickyPaymentButton } from '../payment/sticky-payment-button/sticky-payment-button'
import { OverdueSnapshot, PaymentStatus } from '../../models/payment'
import { useRedirect } from '../../services/router'
import { routes } from '../../routes'
import { withErrorBoundary } from '../error/with-error-boundary'

const errorTypesToCatch = [
  ApiErrorTypes.ServerError,
  ApiErrorTypes.ConnectionError,
  ApiErrorTypes.ClientError,
  ApiErrorTypes.TooManyRequestsError,
  ApiErrorTypes.PermissionError,
]

export const AmountLeftToPayPanel = withErrorBoundary(
  ({ overdue }: { overdue: OverdueSnapshot }) => {
    const { t } = useTranslation()
    const redirect = useRedirect()

    return (
      <section style={{ position: 'absolute', zIndex: 99 }}>
        <StickyPaymentButton
          amount={`${overdue.currency_code} ${overdue.amount}`}
          action={() => {
            if (overdue.pending_payments_count === 1) {
              redirect(routes.paymentDetails, { paymentId: String(overdue.payment_id) })
              return
            }

            redirect(routes.paymentList, { paymentStatus: String(PaymentStatus.Pending) })
          }}
          actionTitle={
            overdue.pending_payments_count === 1 ? t('payment.pay_now') : t('payment.go_to_payments_list_page')
          }
        />
      </section>
    )
  },
  null,
  null,
  errorTypesToCatch,
  true,
)
