import { useTranslation } from 'react-i18next'

import { StickyCallToAction } from '../sticky-call-to-action/sticky-call-to-action'
import { useRedirect } from '../../services/router'
import { routes } from '../../routes'
import { withErrorBoundary } from '../error/with-error-boundary'

export const AttendanceReviewPanel = withErrorBoundary(({ shifts }: { shifts: { id: number; order_id: number }[] }) => {
  const redirect = useRedirect()
  const { t } = useTranslation()

  return (
    <section style={{ position: 'absolute', zIndex: 99 }}>
      <StickyCallToAction
        actionTitle={t('shifts_attendance_list.title')}
        actionSubtitle={t('order.order_number', { id: shifts[0].order_id })}
        action={() => redirect(routes.attendanceShiftDetails, { id: shifts[0].id })}
        buttonTitle={t('shifts_attendance_list.review_now')}
        buttonStyle={{ background: '#FCB35C' }}
      />
    </section>
  )
})
