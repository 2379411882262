import { types, flow, Instance, SnapshotOut } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const ApplicantExperienceModel = types.model('ApplicantExperience', {
  experiences: types.array(
    types.model({
      work_category_name: types.string,
      image_url: types.string,
      company_name: types.string,
      location_name: types.string,
      start_year: types.number,
      end_year: types.number,
      description: types.string,
    }),
  ),
  educations: types.array(
    types.model({
      school: types.string,
      degree: types.string,
      study: types.string,
    }),
  ),
})

export const applicantExperienceActions = (self: DataStore) => ({
  getSPExperience: flow(function* ({ jobId, spId }: { jobId: string; spId: string }) {
    const experiences: ApplicantExperienceSnapshot[] = yield self.request(
      'get',
      `client/cherry-picking/experiences?job_id=${jobId}&sp_id=${spId}`,
    )
    self.applicantsExperience.set(spId, experiences)
  }),
})

export const applicantExperienceViews = (self: DataStore) => ({
  getExperienceBySpId(id: string) {
    return self.applicantsExperience.get(id)
  },
})

export type ApplicantExperience = Instance<typeof ApplicantExperienceModel>
export type ApplicantExperienceSnapshot = SnapshotOut<typeof ApplicantExperienceModel>
