import { Instance, types, cast, addMiddleware } from 'mobx-state-tree'

import { t } from '../../../../translations'
import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export enum AssignmentType {
  preApproval = '1',
  autoAccept = '2',
}

export const ManualToolsModel = types
  .model('ManualTools', {
    use_interview: types.optional(types.boolean, false),
    interview_questions: types.maybeNull(types.array(types.number)),
    interview_error: types.maybeNull(types.string),
    use_manual_selection: types.optional(types.boolean, false),
    manual_selection: types.maybeNull(
      types.enumeration<AssignmentType>('AssignmentType', Object.values(AssignmentType)),
    ),
    manual_selection_error: types.maybeNull(types.string),
  })
  .views(self => ({
    get hasInterviewError() {
      return self.interview_error !== null
    },

    get hasManualSelectionError() {
      return self.manual_selection_error !== null
    },

    hasQuestion(id: number) {
      return Boolean(self.interview_questions?.includes(id))
    },
  }))
  .actions(self => ({
    setManualSelection(selectManually: boolean) {
      self.manual_selection = selectManually ? AssignmentType.preApproval : AssignmentType.autoAccept
      if (!selectManually) {
        self.use_interview = false
        self.interview_questions = null
      }
      self.manual_selection_error = null
    },

    useManualSelection(enableManualSelection: boolean) {
      self.use_manual_selection = enableManualSelection
      if (!enableManualSelection) {
        self.manual_selection = null
        self.use_interview = false
        self.interview_questions = null
      }
      self.manual_selection_error = null
    },
  }))
  .actions(self => ({
    useInterview(interviewRequired: boolean) {
      self.use_interview = interviewRequired
      if (interviewRequired) {
        self.use_manual_selection = interviewRequired
        self.manual_selection = AssignmentType.preApproval
      } else {
        self.interview_questions = null
      }
      self.interview_error = null
    },

    addQuestion(id: number) {
      if (self.interview_questions) {
        self.interview_questions.push(id)
      } else {
        self.interview_questions = cast([id])
      }
      self.interview_error = null
    },

    removeQuestion(id: number) {
      self.interview_questions = cast(self.interview_questions?.filter(item => item !== id))
      self.interview_error = null
    },

    setQuestions(ids: number[]) {
      self.interview_questions = cast(ids)
      self.interview_error = null
    },

    validateManualSelection() {
      if (self.manual_selection !== null) {
        checkValueIsInEnum(self.manual_selection)
      }
      if (self.use_manual_selection && self.manual_selection === null) {
        self.manual_selection_error = t('job_draft_details.manual_selection_required')
        return false
      }
      self.manual_selection_error = null
      return true
    },

    validateInterview() {
      if (self.use_interview && (self.interview_questions === null || self.interview_questions.length === 0)) {
        self.interview_error = t('job_draft_details.interview_required')
        return false
      }
      self.interview_error = null
      return true
    },
  }))
  .actions(self => ({
    validate() {
      self.validateManualSelection()
      self.validateInterview()
      return true
    },
  }))

function checkValueIsInEnum(value: AssignmentType) {
  if (!Object.values(AssignmentType).includes(value)) {
    throw new Error(`Unexpected id ${value} for assignment type`)
  }
}

export function createManualToolsMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.manualStaffSelection) {
      return abort(targetInstance.manualSelection.validateManualSelection())
    } else if (call.type === 'action' && call.args[0] === DraftJobFields.videoInterviews) {
      return abort(targetInstance.manualSelection.validateInterview())
    }
    return next(call)
  })
}

export type ManualToolsInstance = Instance<typeof ManualToolsModel>
