import { useContext } from 'react'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import { useRedirect } from '../../../../services/router'
import { routes } from '../../../../routes'
import { DraftJobContext } from '../job-draft-details/job-draft-details'
import { useDelayedQuery, useStore } from '../../../../utils/mst-hooks'
import { useDraftJobsStore } from '../../../../models-ui/order/store'
import { JobCategorySelector } from '../../../../components/job-category-selector/job-category-selector'
import { Header } from '../../../../components/app-layout'
import { withErrorBoundary } from '../../../../components/error/with-error-boundary'
import { HeaderJobsCounter } from '../../../../components/header-jobs-counter/header-jobs-counter'
import './job-draft-category.scss'

export const JobDraftCategory = withErrorBoundary(
  observer(({ orderId, draftJobId, stepNumber }: { orderId?: string; draftJobId?: string; stepNumber?: string }) => {
    const job = useContext(DraftJobContext)

    const { getBasicOrderJobs, getOrderSummary, getOrderIdSummary } = useStore().data

    const orderSummary = getOrderIdSummary(String(orderId))
    const { enableQuery: getOrderSummaryQuery } = useDelayedQuery(() => getOrderSummary({ orderId: String(orderId) }), {
      filterError: [ApiErrorTypes.ClientError],
    })

    useEffect(() => {
      if (Boolean(orderId)) {
        getOrderSummaryQuery()
      }
    }, [orderId])

    const { createDraftJob, getDraftJob } = useDraftJobsStore()

    const redirect = useRedirect()
    const { t } = useTranslation()

    const handleSelection = (id: number) => {
      const draftJob = draftJobId ? getDraftJob(draftJobId) : createDraftJob()
      draftJob.category.set(id)
      redirect(routes.jobDraftDetails, { orderId, draftJobId: draftJob.id, stepNumber: stepNumber ?? '1' })
    }

    const orderHasJobs = Boolean(orderId ? getBasicOrderJobs(orderId).length : false)

    return (
      <DraftJobContext.Provider value={job}>
        <JobCategorySelector
          handleSelection={handleSelection}
          title={orderHasJobs ? t('job_draft_category.tell_about_job') : t('job_draft_category.tell_about_first_job')}
          subtitle={t('job_draft_category.find_staff')}
          header={
            <Header
              title={t('job_draft_category.title')}
              rightComponent={
                <HeaderJobsCounter jobsCount={(orderSummary?.jobs.length ?? 0) + 1} orderId={Number(orderId)} />
              }
            />
          }
          showStepIndicator
          stepNumber={Number(stepNumber)}
        />
      </DraftJobContext.Provider>
    )
  }),
)
