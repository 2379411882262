import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'

import { useStore, useMutation } from '../../../utils/mst-hooks'
import { UIProps } from './types'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { invoicesListStore } from '../../../models-ui/invoices-list'

export function createInvoiceListContainer(UIComponent: React.FunctionComponent<UIProps>) {
  return withErrorBoundary(
    observer(() => {
      const [isFiltersPopupOpen, setIsFiltersPopupOpen] = useState(false)

      const { invoiceList, getInvoices } = useStore().data

      const [getInvoicesMutation, getInvoicesApiCall] = useMutation(
        ({
          locationId,
          designationId,
          dateFrom,
          dateTo,
          orderId,
          isOverdue,
          page,
        }: {
          locationId?: number | null
          designationId?: number | null
          dateFrom?: string | null
          dateTo?: string | null
          orderId?: number | null
          isOverdue?: boolean
          page?: number
        }) => getInvoices(locationId, designationId, dateFrom, dateTo, orderId, isOverdue, page),
      )

      const fetchInvoicesList = (page?: number) => {
        getInvoicesApiCall({
          locationId: invoicesListStore.searchFilters.locationId,
          designationId: invoicesListStore.searchFilters.designationId,
          dateFrom: invoicesListStore.searchFilters.dateFrom,
          dateTo: invoicesListStore.searchFilters.dateTo,
          orderId: invoicesListStore.searchFilters.orderId,
          isOverdue: invoicesListStore.searchFilters.isOverdue,
          ...(page ? { page } : {}),
        })
      }

      useEffect(() => {
        fetchInvoicesList(1)
      }, [])

      return (
        <UIComponent
          loading={getInvoicesMutation.isLoading}
          invoiceList={invoiceList}
          loadNextPage={() => {
            fetchInvoicesList()
          }}
          filters={{
            activeFiltersCount: invoicesListStore.activeFiltersCount,
            isFiltersPopupOpen,
            setIsFiltersPopupOpen,
            reset: () => {
              invoicesListStore.reset()
              setIsFiltersPopupOpen(false)
              fetchInvoicesList(1)
            },
            apply: () => {
              setIsFiltersPopupOpen(false)
              fetchInvoicesList(1)
            },
            locationId: invoicesListStore.searchFilters.locationId ?? undefined,
            setLocationId: value => invoicesListStore.setLocationId(value ?? null),
            designationId: invoicesListStore.searchFilters.designationId ?? undefined,
            setDesignationId: value => invoicesListStore.setDesignationId(value ?? null),
            dateFrom: invoicesListStore.searchFilters.dateFrom ?? undefined,
            setDateFrom: invoicesListStore.setDateFrom,
            dateTo: invoicesListStore.searchFilters.dateTo ?? undefined,
            setDateTo: invoicesListStore.setDateTo,
            orderId: invoicesListStore.searchFilters.orderId ?? undefined,
            setOrderId: value => invoicesListStore.setOrderId(value ?? null),
            isOverdue: invoicesListStore.searchFilters.isOverdue,
            setIsOverdue: invoicesListStore.setIsOverdue,
          }}
        />
      )
    }),
  )
}
