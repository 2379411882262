import { observer } from 'mobx-react'

import { Select } from '../select/select'
import { useQuery, useStore } from '../../../utils/mst-hooks'
import LoadingSpinner from '../../loading-spinner/loading-spinner'

type InputProps = {
  label: string
  value: number | undefined
  onChange: (val: number) => void
  className: string
}

export const LocationSelect = observer(({ label, value, onChange, className }: InputProps) => {
  const { locationsDataView, getLocations } = useStore().data

  const { isLoading: locationsLoading } = useQuery(getLocations)

  const locationOptions =
    locationsDataView.length > 0
      ? locationsDataView.map(location => ({
          key: String(location.id),
          label: location.name,
          value: location.id,
        }))
      : []

  if (locationsLoading) {
    return <LoadingSpinner />
  }

  return <Select label={label} value={value} onChange={onChange} options={locationOptions} className={className} />
})
