import { Instance, SnapshotOut, addMiddleware, types } from 'mobx-state-tree'

import { t } from '../../../../translations'
import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export const QuantityModel = types
  .model('Quantity', {
    value: types.maybeNull(types.number),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get hasError() {
      return self.error !== null
    },
  }))
  .actions(self => ({
    set(quantity: number) {
      self.value = quantity
      self.error = null
    },

    validate() {
      if (self.value === null || self.value < 1) {
        self.error = t('job_draft_details.quantity_required')
        return false
      }
      self.error = null
      return true
    },
  }))

export function createQuantityMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.quantity) {
      return abort(targetInstance.quantity.validate())
    }
    return next(call)
  })
}

export type QuantitySnapshot = SnapshotOut<typeof QuantityModel>
export type QuantityInstance = Instance<typeof QuantityModel>
