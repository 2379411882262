import { types, flow } from 'mobx-state-tree'
import { values } from 'mobx'

import { DataStore } from './data-store/data-store'

const SPModel = types.model('SPModel', {
  id: types.number,
  name: types.string,
  image: types.string,
})

const ShiftsModel = types.model('ShiftDetailsModel', {
  id: types.number,
  status_id: types.number,
  client_checkin_time: types.maybeNull(types.string),
  client_checkout_time: types.maybeNull(types.string),
  hours: types.number,
  sp: SPModel,
})

const JobModel = types.model('JobModel', {
  id: types.number,
  start_time: types.string,
  end_time: types.string,
  time_to_auto_confirm: types.number,
  pending_shifts: types.number,
  job_id: types.number,
  work_category_name: types.string,
  client_location_name: types.string,
})

export const AttendanceReportShiftModel = types.model('AttendanceReportShiftModel', {
  job: JobModel,
  shifts: types.array(ShiftsModel),
})

export const attendanceReportShiftActions = (self: DataStore) => ({
  fetchAttendanceReportShift: flow(function* (shiftId) {
    const attendanceReportShift = (yield self.request(
      'get',
      `client/attendance/shifts/${shiftId}`,
    )) as AttendanceReportShiftModel
    self.attendanceReportShift = attendanceReportShift
  }),
  updateAttendanceReportShift: flow(function* (updatedAttendance) {
    yield self.request('post', `client/attendance/shifts/process`, updatedAttendance)
  }),
})

export const attendanceReportShiftViews = (self: DataStore) => ({
  attendanceReportShiftView() {
    return {
      job: self.attendanceReportShift?.job,
      shifts: self.attendanceReportShift?.shifts ? values(self.attendanceReportShift.shifts) : [],
    }
  },
})

export type AttendanceReportShiftModel = typeof AttendanceReportShiftModel.Type
export type ShiftsModel = typeof ShiftsModel.Type
export type AttendanceReportShiftModelSnapshot = typeof AttendanceReportShiftModel.SnapshotType
