import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import copyImg from './assets/copy.svg'
import trashImg from './assets/trash.svg'
import './job-card.scss'
import {
  IntervalModelSnapshot,
  PricingModelSnapshot,
  ShiftRepeatType,
} from '../../../../../models/order/order-job-details'
import { Pricing } from '../pricing/pricing'
import { ExtendedJobFields } from '../extended-job-fields/extended-job-fields'
import { SchedulePopup } from '../schedule-popup/schedule-popup'
import { EditJobButton } from '../edit-job-button/edit-job-button'
import { InvitedSP } from '../../../../../components/invited-sp/invited-sp'
import { PastSPSnapshot } from '../../../../../models/past-workers'

type JobCardProps = {
  title: string
  quantity: number
  startDate: string
  endDate: string
  daysCount: number
  locationName: string
  onJobPress: () => void
  onScheduleEdit: () => void
  onDelete: () => void
  onDuplicate: () => void
  isAutoPricingEnabled: boolean
  pricing: PricingModelSnapshot
  currency: string
  isExtension: boolean
  intervals: [string, IntervalModelSnapshot][]
  repeatType: ShiftRepeatType
  shiftsCount: number
  invitedSPs: PastSPSnapshot[]
}

export const JobCard = ({
  title,
  quantity,
  startDate,
  endDate,
  daysCount,
  locationName,
  onJobPress,
  onScheduleEdit,
  onDelete,
  onDuplicate,
  isAutoPricingEnabled,
  pricing,
  currency,
  isExtension,
  intervals,
  repeatType,
  shiftsCount,
  invitedSPs,
}: JobCardProps) => {
  const [showSchedulePopup, setShowSchedulePopup] = useState<boolean>(false)
  const isScheduledWeekly = repeatType === ShiftRepeatType.EveryWeek

  const { t } = useTranslation()

  return (
    <div onClick={isExtension ? () => {} : onJobPress} className="JobCard">
      <div className="JobCard__header">
        <span className="JobCard__header__title">{title}</span>
        <div className="JobCard__header__rightContainer">
          {!isExtension ? (
            <>
              <EditJobButton onClick={onJobPress} containerStyle={{ marginInlineEnd: '15px' }} />
              <img
                onClick={e => {
                  e.stopPropagation()
                  onDuplicate()
                }}
                src={copyImg}
                className="JobCard__header__rightContainer__copyImg"
              />
              <img
                onClick={e => {
                  e.stopPropagation()
                  onDelete()
                }}
                src={trashImg}
                className="JobCard__header__rightContainer__deleteImg"
              />
            </>
          ) : (
            <EditJobButton onClick={onJobPress} />
          )}
        </div>
      </div>
      <div className="JobCard__row">
        <span className="JobCard__row__label">{t('order_draft.quantity')}</span>
        <span className="JobCard__row__value">{quantity}</span>
      </div>
      <div className="JobCard__separator" />
      <div className="JobCard__row">
        <span className="JobCard__row__label">{t('order_draft.days')}</span>
        <div>
          <span className="JobCard__row__value">
            {startDate} - {endDate}
          </span>{' '}
          <span className="JobCard__row__hint">{t('order_draft.days_amount', { count: shiftsCount })}</span>
        </div>
      </div>
      <div className="JobCard__separator" />
      <div className="JobCard__row">
        <span className="JobCard__row__label">{t('order_draft.location')}</span>
        <span className="JobCard__row__value">{locationName}</span>
      </div>
      <div className="JobCard__separator" />
      {invitedSPs.length > 0 && (
        <div className="JobCard__row JobCard__row--vertical">
          <span className="JobCard__row__label">{t('invited_sps.invited_sps')}</span>
          <div>
            {invitedSPs.map(sp => (
              <InvitedSP
                key={sp.id}
                disabled={false}
                withCheckbox={false}
                sp={sp}
                selected={true}
                showDesignation={false}
              />
            ))}
          </div>
        </div>
      )}
      {isExtension && (
        <ExtendedJobFields
          startDate={startDate}
          endDate={endDate}
          daysCount={shiftsCount}
          intervals={intervals}
          showSchedulePopup={() => setShowSchedulePopup(true)}
        />
      )}
      {isAutoPricingEnabled && <Pricing pricing={pricing} currency={currency} />}
      <SchedulePopup
        showPopup={showSchedulePopup}
        title={`${title} (${quantity})`}
        isScheduledWeekly={isScheduledWeekly}
        daysCount={daysCount}
        intervals={intervals}
        openEditSchedulePage={onScheduleEdit}
        closePopup={() => setShowSchedulePopup(false)}
      />
    </div>
  )
}
