import { OnboardingContent } from '../../../page-onboarding/page-onboarding'
import { t } from '../../../../translations'
import { routes } from '../../../../routes'
import { jobDurationPage } from '../pages/job-duration'

export const jobDurationOnboarding: OnboardingContent = {
  title: t('order_creation_onboarding.title'),
  paths: [`${routes.jobDraftDetails.path}/dates`],
  pages: [jobDurationPage],
}
