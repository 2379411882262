import { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { useTranslation } from 'react-i18next'

import check from '../../../components/assets/check-icon-green.svg'
import cross from '../../../components/assets/cross-icon-red.svg'
import ShiftCard from '../components/shift-card/shift-card'
import ShiftConfirmed from '../components/shift-confirmed/shift-confirmed'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import AttendanceCard, { Job } from '../components/attendance-card/attendance-card'
import { routerContext, useRedirect } from '../../../services/router'
import { routes } from '../../../routes'
import { useMutation, useQuery, useStore } from '../../../utils/mst-hooks'
import { ShiftsModel } from '../../../models/attendance-report-shift'
import { Header, Separator } from '../../../components/app-layout'
import './shift-details.scss'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { DAY_OFF_REASON_ID } from '../../../models/shift-cancel-reason'

export interface AttendanceData {
  check_in_time?: string | null
  check_out_time?: string | null
  action: string
  id: number
  reason?: number | string
}

const ShiftDetails = withErrorBoundary(
  observer(() => {
    const router = useContext(routerContext)
    const {
      cancelReasons,
      fetchAttendanceReportShift,
      attendanceReportShiftView,
      updateAttendanceReportShift,
      fetchShiftCancelReasons,
    } = useStore().data
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('id') as string
    const fetchShiftDetailsQuery = useQuery(() => fetchAttendanceReportShift(id), {
      filterError: [ApiErrorTypes.ClientError],
    })
    const shiftDetails = attendanceReportShiftView()
    const [attendanceData, setAttendanceData] = useState<AttendanceData[]>([])
    const [showConfirmationScreen, setShowConfirmationScreen] = useState(false)
    const [attendedCount, setAttendanceCount] = useState<number>(0)
    const [notAttendedCount, setNotAttendedCount] = useState<number>(0)
    const [updateShiftAttendanceStatus, updateAttendance] = useMutation(updateAttendanceReportShift, {
      onSuccess: () => setShowConfirmationScreen(true),
      filterError: [ApiErrorTypes.ClientError],
    })
    const navigate = useRedirect()

    const fetchCancelReasonsQuery = useQuery(fetchShiftCancelReasons)

    useEffect(() => {
      if (!cancelReasons.length) {
        return
      }
      setAttendanceCount(shiftDetails.shifts.filter((shift) => Boolean(shift.client_checkin_time)).length)
      setNotAttendedCount(shiftDetails.shifts.filter((shift) => !shift.client_checkin_time).length)
      setAttendanceData(initiateShiftAttendance())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchShiftDetailsQuery.isLoading, fetchCancelReasonsQuery.isLoading])

    const { t } = useTranslation()

    function initiateShiftAttendance() {
      return shiftDetails.shifts.map((shift): AttendanceData => {
        if (shift.client_checkin_time && shift.client_checkout_time) {
          return {
            id: shift.id,
            check_in_time: shift.client_checkin_time,
            check_out_time: shift.client_checkout_time,
            action: 'confirm',
          }
        }
        return {
          id: shift.id,
          action: 'cancel',
          reason: cancelReasons.find((reason) => reason.id === DAY_OFF_REASON_ID)?.id,
        }
      })
    }

    function closeConfirmation() {
      router.push(routes.attendanceShiftsList.path)
    }

    const retrieveAttendanceData = (shiftAttendanceData: AttendanceData) => {
      const existedAttendanceData = attendanceData.find((data) => data.id === shiftAttendanceData.id) as AttendanceData
      if (existedAttendanceData.action !== shiftAttendanceData.action) {
        if (shiftAttendanceData.action === 'cancel') {
          setAttendanceCount((count) => (count - 1 >= 0 ? count - 1 : 0))
          setNotAttendedCount((count) => count + 1)
        } else if (shiftAttendanceData.action === 'confirm') {
          setAttendanceCount((count) => count + 1)
          setNotAttendedCount((count) => (count - 1 >= 0 ? count - 1 : 0))
        }
      }

      setAttendanceData((data: AttendanceData[]) => {
        data[data.indexOf(existedAttendanceData)] = shiftAttendanceData
        return [...data]
      })
    }

    const confirmShift = () => updateAttendance({ shifts: attendanceData })
    const navigateBack = () => {
      navigate(routes.attendanceShiftsList, {})
    }
    return (
      <>
        {fetchShiftDetailsQuery.isLoading || updateShiftAttendanceStatus.isLoading ? (
          <LoadingSpinner />
        ) : showConfirmationScreen ? (
          <ShiftConfirmed closeConfirmation={closeConfirmation} />
        ) : (
          <div className="ShiftDetails">
            <Header showBack={true} handleBackClick={navigateBack} title={shiftDetails.job?.work_category_name} />
            <Separator.Vertical height={38} />
            {/* <Link route={routes.attendanceShiftsList} params={{}}>
            <img className="backIcon" src={back} />
          </Link>

          <h2 className="ShiftDetails__title">{shiftDetails.job?.work_category_name}</h2> */}
            <div className="ShiftDetails__shiftContainer">
              <div className="ShiftDetails__shiftBasic">
                <ShiftCard showLimitedTimeModal={true} shiftData={shiftDetails.job as Job} />
              </div>
              <div className="ShiftDetails__attendanceList">
                {shiftDetails.shifts.map((shift, i) => (
                  <AttendanceCard
                    onUpdate={retrieveAttendanceData}
                    key={i}
                    attendanceShift={{ ...shift } as ShiftsModel}
                    job={shiftDetails.job as Job}
                  />
                ))}
              </div>
              <div className="ShiftDetails__confirm">
                <div className="ShiftDetails__attendanceCount">
                  <div>
                    <img src={check} />
                    <strong>{attendedCount}</strong>
                    <span> {t('shifts_attendance_details.attended')}</span>
                  </div>
                  <div>
                    <img src={cross} />
                    <strong>{notAttendedCount}</strong>
                    <span> {t('shifts_attendance_details.did_not')}</span>
                  </div>
                </div>
                <button onClick={confirmShift} className="ShiftDetails__confirmButton">
                  {t('shifts_attendance_details.confirm_amount', { count: shiftDetails.shifts.length })}
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }),
)

export default ShiftDetails
