import { createContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { DraftJobInstance } from '../../../../models-ui/order/store/draft-job'
import { useDraftJobsStore } from '../../../../models-ui/order/store'
import { JobDraftDetailsFormComponent } from '../../../../components/job-draft-details-form/job-draft-details-form-component'
import { withErrorBoundary } from '../../../../components/error/with-error-boundary'
import { DraftJobFields } from '../../../../models-ui/order/store/job-fields/fields'
import './job-draft-details.scss'

type JobDraftDetailsProps = {
  orderId?: string
  draftJobId: string
  activePopupField?: DraftJobFields | null
  stepNumber?: string
  isExtension: boolean
}
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DraftJobContext = createContext<DraftJobInstance>(null!)

export const JobDraftDetailPage = withErrorBoundary(
  observer(({ orderId, draftJobId, activePopupField, stepNumber, isExtension }: JobDraftDetailsProps) => {
    const { t } = useTranslation()
    const { getDraftJob } = useDraftJobsStore()
    const job = getDraftJob(draftJobId)

    return (
      <DraftJobContext.Provider value={job}>
        <JobDraftDetailsFormComponent
          isExtension={isExtension}
          orderId={orderId}
          orderDraftTitle={t('order_draft.title')}
          formSubtitle={t('job_draft_details.fill_job_details')}
          activePopupField={activePopupField}
          job={job}
          getFormTitle={professionName => {
            return t('job_draft_details.title', { category: professionName })
          }}
          showStepIndicator
          stepNumber={Number(stepNumber)}
        />
      </DraftJobContext.Provider>
    )
  }),
)
