import { types, flow, SnapshotIn, SnapshotOut, Instance } from 'mobx-state-tree'

import { DataStore } from '../data-store/data-store'
import { BasicOrderSnapshot } from './basic-order'

export enum ShiftRepeatType {
  NoRepeat = 0,
  EveryDay = 1,
  EveryWeek = 2,
}

const PricingMultiplierModel = types.model('PricingMultiplier', {
  type: types.string,
  hours: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
  valueType: types.string,
})

const PricingModel = types.model('Pricing', {
  total: types.maybeNull(types.number),
  final_total: types.maybeNull(types.number),
  details: types.array(PricingMultiplierModel),
})

const IntervalModel = types.model('Interval', {
  start_time: types.string,
  end_time: types.string,
  day_of_month: types.string,
  month: types.string,
  day: types.string,
  duration: types.number,
})

export const OrderJobDetailsModel = types.model('JobDetails', {
  id: types.identifierNumber,
  work_category_id: types.number,
  category_name: types.string,
  quantity: types.number,
  public_transport_accessibility: types.maybeNull(types.number),
  instructions: types.string,
  is_automatic_checkout: types.number,
  invited_sp_ids: types.array(types.number),
  is_accreditation_required: types.number,
  is_accommodation_offered: types.number,
  is_interview_required: types.number,
  boarding_type: types.number,
  start_date: types.string,
  end_date: types.string,
  uniform_id: types.number,
  uniform_provided: types.string,
  client_location_id: types.number,
  client_location_name: types.string,
  contact_user_id: types.maybeNull(types.number), // null to support legacy jobs
  seniority_level: types.number,
  filter: types.model({
    languages: types.maybeNull(types.array(types.number)),
    certificates: types.maybeNull(types.array(types.number)),
    gender: types.maybeNull(types.number),
  }),
  assignment_type: types.number,
  work_requirement_ids: types.array(types.number),
  intervals: types.map(IntervalModel),
  is_recurring: types.number,
  pricing: PricingModel,
  repeat_type: types.maybeNull(types.number),
})

export type OrderJobDetails = SnapshotIn<typeof OrderJobDetailsModel>
export type OrderJobDetailsSnapshot = SnapshotOut<typeof OrderJobDetailsModel>
export type OrderJobDetailsInstance = Instance<typeof OrderJobDetailsModel>

export const orderJobDetailsActions = (self: DataStore) => ({
  getOrderJobs: flow(function* (params: { order_id: number }) {
    const order = self.order.draft.get(String(params.order_id))
    if (!order) {
      const updatedOrder = (yield self.request('post', 'client/order/basic/get', {
        order_id: params.order_id,
      })) as Omit<BasicOrderSnapshot, 'jobs'>
      self.order.draft.set(String(updatedOrder.id), updatedOrder)
    }
    const jobs = (yield self.request('post', `client/order/jobs/get`, params)) as OrderJobDetailsSnapshot[]
    jobs.forEach(job => {
      self.order.draft.get(String(params.order_id))?.jobs.set(String(job.id), job)
    })
  }),

  cancelJob: flow(function* ({ orderId, jobId }: { orderId: string; jobId: number }) {
    yield self.request('post', 'client/order/jobs/delete', { job_id: jobId })
    self.order.draft.get(orderId)?.jobs.delete(String(jobId))
  }),
})

export const orderJobDetailsViews = (self: DataStore) => ({
  getOrderIdJobs(orderId: number) {
    const order = self.order.draft.get(String(orderId))
    if (!order) {
      return []
    }
    return Array.from(order.jobs.values())
  },

  getOrderIdJob(orderId: string, jobId: string) {
    const order = self.order.draft.get(String(orderId))
    return order?.jobs.get(jobId)
  },

  getJobIntervals(orderId: string, jobId: string) {
    const order = self.order.draft.get(String(orderId))
    const job = order?.jobs.get(jobId)

    if (!job) {
      return []
    }

    return Array.from(job.intervals.entries())
  },
})

export type PricingModelSnapshot = SnapshotOut<typeof PricingModel>
export type PricingMultiplierModelSnapshot = SnapshotOut<typeof PricingMultiplierModel>
export type IntervalModelSnapshot = SnapshotOut<typeof IntervalModel>
export type IntervalModelInstance = Instance<typeof IntervalModel>
