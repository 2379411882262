import { ComponentType } from 'react'

import { OnboardingContent, PageOnboarding } from '../page-onboarding/page-onboarding'
import './with-onboarding.scss'

export function OnboardingWrapper<T extends Record<string, unknown>>({
  Component,
  Content,
  onboardingVisible,
  showHelpButton,
  componentProps,
  setOnboardingVisible,
  helpButtonAdditionalStyle,
}: {
  Component: ComponentType<T>
  Content?: OnboardingContent
  onboardingVisible: boolean
  showHelpButton: boolean
  componentProps: T
  setOnboardingVisible: (newValue: boolean) => void
  helpButtonAdditionalStyle?: object
}) {
  return (
    <>
      {onboardingVisible && Content && (
        <PageOnboarding
          content={Content}
          initialIndex={0}
          visible={onboardingVisible}
          onClose={() => {
            setOnboardingVisible(false)
          }}
        />
      )}
      {showHelpButton && (
        <div
          onClick={() => {
            setOnboardingVisible(true)
          }}
          className="WithOnboarding__floatingButton"
          style={helpButtonAdditionalStyle}
        >
          <span className="WithOnboarding__floatingButton__label">?</span>
        </div>
      )}
      <Component {...componentProps} />
    </>
  )
}
