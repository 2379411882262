import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useState, useEffect } from 'react'

import { ListItemOrderInstance, ListItemOrderSnapshot, OrderState } from '../../../models/order/list-item-orders'
import { useRedirect } from '../../../services/router'
import { useStore, useMutation } from '../../../utils/mst-hooks'
import { useBottomListListener } from '../../../utils/use-bottom-list-listener'
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner'
import { Separator } from '../../../components/app-layout/separator/separator'
import { Header } from '../../../components/app-layout/header/header'
import { OrderCardWithProgress } from './order-card-with-progress/order-card-with-progress'
import { routes } from '../../../routes'
import { EmptyOrderList } from './empty-order-list/empty-order-list'
import { Tabs } from '../../../components/tabs/tabs'
import './orders-list.scss'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { orderExtensionStore } from '../../../models-ui/order/store'
import { ExtendOrderPopup } from '../../../components/extend-order-popup/extend-order-popup'
import { OrderCard } from './order-card/order-card'
import { FiltersButton } from '../../../components/filters/filters-button/filters-button'
import { FiltersPopup } from '../../../components/filters/filters-popup/filters-popup'
import { ordersListStore } from '../../../models-ui/orders-list'

export const OrdersListPage = withErrorBoundary(
  observer(({ ordersState }: { ordersState: string }) => {
    const redirect = useRedirect()
    const { t } = useTranslation()

    const [isFiltersPopupOpen, setIsFiltersPopupOpen] = useState(false)
    const [orderIdToExtend, setOrderIdToExtend] = useState<number | null>(null)

    const tabs = [
      {
        title: t('orders_list.tab_title_pending'),
        onClick: () => redirect(routes.orders, { ordersState: OrderState.Unconfirmed }),
        isActive: ordersState === OrderState.Unconfirmed,
      },
      {
        title: t('orders_list.tab_title_active'),
        onClick: () => redirect(routes.orders, { ordersState: OrderState.Active }),
        isActive: ordersState === OrderState.Active,
      },
      {
        title: t('orders_list.tab_title_completed'),
        onClick: () => redirect(routes.orders, { ordersState: OrderState.Completed }),
        isActive: ordersState === OrderState.Completed,
      },
    ]

    const { orders, getOrders } = useStore().data
    const [getOrdersMutation, getOrdersApiCall] = useMutation(
      ({
        state,
        locationId,
        designationId,
        startDate,
        endDate,
        page,
      }: {
        state: string
        locationId?: number | null
        designationId?: number | null
        startDate?: string | null
        endDate?: string | null
        page?: number
      }) => getOrders(state, locationId, designationId, startDate, endDate, page),
    )

    const fetchOrdersList = (page?: number) => {
      getOrdersApiCall({
        state: ordersState,
        locationId: ordersListStore.searchFilters.locationId,
        designationId: ordersListStore.searchFilters.designationId,
        startDate: ordersListStore.searchFilters.startDate,
        endDate: ordersListStore.searchFilters.endDate,
        ...(page ? { page } : {}),
      })
    }

    useEffect(() => {
      getOrdersApiCall({
        state: ordersState,
        locationId: ordersListStore.searchFilters.locationId,
        designationId: ordersListStore.searchFilters.designationId,
        startDate: ordersListStore.searchFilters.startDate,
        endDate: ordersListStore.searchFilters.endDate,
        page: 1,
      })
    }, [ordersState])

    useEffect(() => {
      const order = orders.find((item: ListItemOrderSnapshot) => item.id === orderIdToExtend)
      if (order) {
        orderExtensionStore.clear()
        orderExtensionStore.createFromOrderModel(order)
      }
    }, [orderIdToExtend])

    const handleScroll = useBottomListListener(fetchOrdersList)

    const getOrderStatus = (order: ListItemOrderInstance) => {
      if (order.is_pending_rates_review) {
        return 'underReview'
      }
      if (order.is_pending_client_approval) {
        return 'pendingConfirmation'
      }
      if (order.is_draft) {
        return 'draft'
      }
      if (moment().isAfter(order.start_date)) {
        return 'started'
      }

      return 'upcoming'
    }

    return (
      <div className="OrderConfirmationList" onScroll={handleScroll}>
        <Header
          title={t('orders_list.title')}
          rightComponent={
            <FiltersButton
              setShowFilters={setIsFiltersPopupOpen}
              activeFiltersCount={ordersListStore.activeFiltersCount}
              className="OrderConfirmationList__filtersButton"
            />
          }
        />
        <Separator.Vertical height={35} />
        <div className="OrderConfirmationList__container">
          <Tabs tabs={tabs} />
          <Separator.Vertical height={20} />
          {!getOrdersMutation.isLoading && !orders.length && <EmptyOrderList />}
          {Boolean(orders.length) &&
            orders.map(order => {
              const orderStatus = getOrderStatus(order)
              const innerComponent =
                ordersState === OrderState.Active ? (
                  <OrderCardWithProgress
                    openOrderExtensionPopup={() => setOrderIdToExtend(order.id)}
                    openOrderDuplicationPage={() =>
                      redirect(routes.orderDuplicateChooseJobs, { orderId: String(order.id) })
                    }
                    id={order.id}
                    startDateString={order.start_date}
                    endDateString={order.end_date}
                    daysTillEndCount={order.days_till_end_count}
                    jobs={order.jobs}
                    status={orderStatus}
                    showStatusLabel={orderStatus === 'upcoming'}
                  />
                ) : (
                  <OrderCard
                    id={order.id}
                    startDateString={order.start_date}
                    endDateString={order.end_date}
                    status={orderStatus}
                    jobs={order.jobs}
                    showStatusLabel={ordersState !== OrderState.Completed}
                  />
                )
              if (order.is_draft) {
                return (
                  <div
                    key={order.id}
                    onClick={() =>
                      redirect(routes.orderDraft, { orderId: String(order.id), title: t('order.amend_order') })
                    }
                    className="OrderConfirmationList__container__cardContainer"
                  >
                    {innerComponent}
                  </div>
                )
              }
              return (
                <div
                  key={order.id}
                  onClick={() => redirect(routes.orderConfirmation, { orderId: String(order.id) })}
                  className="OrderConfirmationList__container__cardContainer"
                >
                  {innerComponent}
                </div>
              )
            })}
          {getOrdersMutation.isLoading && <LoadingSpinner />}
          <Separator.Vertical height={30} />
          <ExtendOrderPopup isOpen={orderIdToExtend !== null} close={() => setOrderIdToExtend(null)} clearOnClose />
          <FiltersPopup
            isOpen={isFiltersPopupOpen}
            close={() => setIsFiltersPopupOpen(false)}
            reset={() => {
              ordersListStore.reset()
              setIsFiltersPopupOpen(false)
              fetchOrdersList(1)
            }}
            apply={() => {
              setIsFiltersPopupOpen(false)
              fetchOrdersList(1)
            }}
            fields={['locationId', 'designationId', 'startDate', 'endDate']}
            locationId={ordersListStore.searchFilters.locationId ?? undefined}
            setLocationId={value => ordersListStore.setLocationId(value ?? null)}
            designationId={ordersListStore.searchFilters.designationId ?? undefined}
            setDesignationId={value => ordersListStore.setDesignationId(value ?? null)}
            startDate={ordersListStore.searchFilters.startDate ?? undefined}
            setStartDate={ordersListStore.setStartDate}
            endDate={ordersListStore.searchFilters.endDate ?? undefined}
            setEndDate={ordersListStore.setEndDate}
          />
        </div>
      </div>
    )
  }),
)
