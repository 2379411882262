import './calendar-header.scss'
import { CalendarLocationFilter } from '../calendar-location-filter/calendar-location-filter'
import { WeekToggle } from '../week-toggle/week-toggle'

type CalendarHeader = {
  month: string
  year: string
  locationId: number | null
  setLocationId: (value: number | null) => void
  onPreviousWeekPress: () => void
  onNextWeekPress: () => void
}

export function CalendarHeader({
  month,
  year,
  onPreviousWeekPress,
  onNextWeekPress,
  locationId,
  setLocationId,
}: CalendarHeader) {
  return (
    <div className="CalendarHeader__container">
      <div className="CalendarHeader__top mobile-only">
        <span className="CalendarHeader__label">
          {month} <span className="CalendarHeader__label--dimmed">{year}</span>
        </span>
        <WeekToggle onPreviousWeekPress={onPreviousWeekPress} onNextWeekPress={onNextWeekPress} />
      </div>
      <div className="CalendarHeader">
        <div className="CalendarHeader__left">
          <span className="CalendarHeader__label desktop-only">
            {month} <span className="CalendarHeader__label--dimmed">{year}</span>
          </span>
        </div>
        <div className="CalendarHeader__right">
          <CalendarLocationFilter locationId={locationId} setLocationId={setLocationId} />
          <WeekToggle
            onPreviousWeekPress={onPreviousWeekPress}
            onNextWeekPress={onNextWeekPress}
            additionalContainerClassName="desktop-only"
          />
        </div>
      </div>
    </div>
  )
}
