import { Instance, types, addMiddleware } from 'mobx-state-tree'

import { t } from '../../../../translations'
import { Experience } from '../../../../models/seniority-level'
import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export const ExperienceModel = types
  .model('Experience', {
    value: types.maybeNull(types.enumeration<Experience>('Experience', Object.values(Experience))),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get hasError() {
      return self.error !== null
    },
  }))
  .actions(self => ({
    set(experience: Experience) {
      checkValueIsInEnum(experience)
      self.value = experience
      self.error = null
    },

    validate() {
      if (self.value === null) {
        self.error = t('job_draft_details.experience_required')
        return false
      }
      checkValueIsInEnum(self.value)
      self.error = null
      return true
    },
  }))

function checkValueIsInEnum(value: Experience) {
  if (!Object.values(Experience).includes(value)) {
    throw new Error(`Unexpected id ${value} for experience`)
  }
}

export function createExperienceMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.experience) {
      return abort(targetInstance.experience.validate())
    }
    return next(call)
  })
}

export type ExperienceInstance = Instance<typeof ExperienceModel>
