import { useEffect, useRef, useState, Fragment, ChangeEvent } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'
import { observer } from 'mobx-react'
import { toast, ToastContainer } from 'react-toastify'

import { useDelayedQuery, useMutation, useQuery, useStore } from '../../../utils/mst-hooks'
import OrderSummaryWelcome from '../components/order-summary-welcome/order-summary-welcome'
import fileIcon from '../assets/file-icon.svg'
import inviteIcon from '../assets/invite-user-icon.svg'
import costIcon from '../assets/cost-icon.svg'
import SectionTitle from '../components/section-title/section-title'
import OrderSummaryCard from '../components/order-summary-card/order-summary-card'
import OrderSummaryJobsCard from '../components/order-summary-jobs-card/order-summary-jobs-card'
import OrderSummaryCostCard from '../components/order-summary-cost-card/order-summary-cost-card'
import { UserRole } from '../../../models/account'
import { routes } from '../../../routes'
import { useRedirect } from '../../../services/router'
import { ProtectedRoute } from '../../../components/protected-route/protected-route'
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner'
import { Button } from '../../../components/button/button'
import { Header } from './components/header/header'
import { Separator } from '../../../components/app-layout/separator/separator'
import { AmendModal } from '../components/amend-modal/amend-modal'
import { ActionAlert, Theme } from '../../../components/action-alert/action-alert'
import { useFileService } from '../../../utils/use-file-service'
import { trackingService, TrackedEvent } from '../../../services/tracking-service'
import { StickyPanel } from '../../../components/sticky-panel/sticky-panel'
import { useIsPaymentsEnabled } from '../../../services/payment-service'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { getCurrentMarketConfig } from '../../../services/market-service'
import { CLIENT_STATUS_ACTIVE } from '../../../constants'
import { AcceptTerms } from '../components/accept-terms/accept-terms'
import { orderExtensionStore } from '../../../models-ui/order/store'
import { ExtendOrderPopup } from '../../../components/extend-order-popup/extend-order-popup'
import './order-summary.scss'
import { OrderSummarySnapshot } from '../../../models/order/order-summary'

const OrderSummary = withErrorBoundary(
  observer(({ orderId }: { orderId: string }) => {
    const [amendModalVisible, setAmendModalVisible] = useState(false)
    const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState(false)
    const [orderExtensionPopupVisible, setOrderExtensionPopupVisible] = useState<boolean>(false)

    const {
      apiHost,
      getOrderSummary,
      confirmOrder,
      confirmOrderTermsConditions,
      getOrderIdSummary,
      downloadQuotation,
      uploadLPO,
      setIsPendingLPOUpload,
      setQuoteWasDownloaded,
      downloadLPO,
      setLPOWasUploaded,
      getUpfrontPaymentId,
      companyDetails,
      getСompanyDetails,
      getInvitedPastSPsByIds,
      invitedPastSPsList,
    } = useStore().data

    useQuery(getСompanyDetails)

    const [, getInvitedPastSPsByIdsQuery] = useMutation(({ ids }: { ids: number[] }) => getInvitedPastSPsByIds(ids), {
      onError: err => {
        toast.error(err.data.message)
      },
    })

    const orderSummary = getOrderIdSummary(orderId)
    const orderSummaryQuery = useQuery(() => getOrderSummary({ orderId }), {
      filterError: [ApiErrorTypes.ClientError],
      onSuccess: (res: OrderSummarySnapshot) => {
        const invitedSPIds: number[] = []

        res.jobs.forEach(job => {
          job.invited_sp_ids.forEach(invitedSPId => {
            if (!invitedSPIds.includes(invitedSPId)) {
              invitedSPIds.push(invitedSPId)
            }
          })
        })

        getInvitedPastSPsByIdsQuery({ ids: invitedSPIds })
      },
      onError: err => {
        toast.error(err.data.message)
      },
    })

    const [confirmOrderQuery, requestConfirmOrder] = useMutation(confirmOrder, {
      onSuccess: () => {
        redirect(routes.orderCreateSuccess, { orderId })
      },
      onError: err => {
        // Makes Sentry to have it as a separate error with all logs/breadcrumbs
        console.error('Confirm Order Summary API Error')
        alert(err.data.message)
      },
      filterError: [ApiErrorTypes.ClientError],
    })

    const [confirmTermsQuery, requestConfirmTerms] = useMutation(confirmOrderTermsConditions, {
      onSuccess: () => {
        setIsTermsAndConditionsAccepted(true)
      },
      onError: err => alert(err.data.message),
      filterError: [ApiErrorTypes.ClientError],
    })

    const redirect = useRedirect()

    const { t } = useTranslation()

    const { download: saveFile } = useFileService()
    const { enableQuery: downloadQuotationQuery, query: downloadQuotationResult } = useDelayedQuery(
      () => downloadQuotation(orderId),
      {
        onSuccess: file => {
          saveFile(file, `order_quotation_${orderId}.pdf`)
          setQuoteWasDownloaded(orderId, true)
        },
      },
    )

    const { enableQuery: downloadLPOQuery, query: downloadLPOResult } = useDelayedQuery(() => downloadLPO(orderId), {
      onSuccess: file => {
        saveFile(file, `order_lpo_${orderId}.pdf`)
        setQuoteWasDownloaded(orderId, true)
      },
    })

    useEffect(() => {
      if (orderSummaryQuery.isLoading) return

      if (!hideConfirmation && (showNonUpfrontConfirmationButton || showAcceptTerms)) {
        trackingService.track(TrackedEvent.ViewedConfirmOrderSummary)
      } else {
        trackingService.track(TrackedEvent.ViewedSubmittedOrderSummary)
      }
    }, [orderSummaryQuery.isLoading])

    useEffect(() => {
      if (orderSummary) {
        orderExtensionStore.clear()
        orderExtensionStore.createFromOrderModel(orderSummary)
      }
    }, [orderSummary])

    const LPOFileInputRef = useRef<HTMLInputElement>(null)
    const [uploadLPOResult, uploadLPORequest] = useMutation(({ file }: { file: File }) => uploadLPO(orderId, file), {
      onSuccess: () => {
        setIsPendingLPOUpload(orderId, false)
        setLPOWasUploaded(orderId, true)

        toast.success(t('order.lpo_upload_success_message'))
      },
      onError: err => {
        const uploadLPOErrorMessage = err.data.message
        const uploadLPOErrors = err.data.errors?.file ?? []
        const uploadLPOErrorText = uploadLPOErrors.length > 0 ? uploadLPOErrors.join(';') : uploadLPOErrorMessage

        toast.error(uploadLPOErrorText)
      },
      filterError: [ApiErrorTypes.ClientError],
    })
    const handleUploadLPO = (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (!file) {
        return
      }

      event.target.value = ''
      uploadLPORequest({ file })
    }

    const showDownloadQuoteActionAlert =
      Boolean(orderSummary?.is_pending_client_approval) && !orderSummary?.quote_was_downloaded

    const { enableQuery: pay, query: getUpfrontPaymentIdResult } = useDelayedQuery(() => getUpfrontPaymentId(orderId), {
      onSuccess: paymentId => {
        redirect(routes.paymentMethodsList, {
          paymentId: String(paymentId),
          paymentOptions: JSON.stringify({
            redirectToRouteAfterSuccess: 'orderConfirmation',
            redirectToRouteAfterSuccessParams: {
              orderId: String(orderId),
            },
            redirectToRouteAfterSuccessTitle: t('payment.back_to_order'),
          }),
        })
      },
    })

    const showLoading = orderSummaryQuery.isLoading || confirmOrderQuery.isLoading
    const disableConfirmButton = !isTermsAndConditionsAccepted || getUpfrontPaymentIdResult.isLoading

    const isPaymentsEnabled = useIsPaymentsEnabled()
    const hidePaymentFunctionality = !isPaymentsEnabled()

    const isUserProfileCompleted = Boolean(companyDetails?.status_id === CLIENT_STATUS_ACTIVE)
    const hideConfirmation = getCurrentMarketConfig().registration.allowOnlyCorporate && !isUserProfileCompleted

    const showNonUpfrontConfirmationButton =
      (!hidePaymentFunctionality &&
        Boolean(orderSummary?.is_pending_client_approval) &&
        !Boolean(orderSummary?.is_upfront)) ||
      (hidePaymentFunctionality && Boolean(orderSummary?.is_pending_client_approval))
    const showAcceptTerms =
      !hidePaymentFunctionality &&
      !Boolean(orderSummary?.is_draft) &&
      !Boolean(orderSummary?.is_pending_rates_review) &&
      orderSummary?.is_upfront &&
      !orderSummary.is_paid

    return (
      <>
        {showLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="OrderSummary">
            <ToastContainer autoClose={3000} />
            <Header
              orderId={orderId}
              isQuoteDownloading={downloadQuotationResult.isLoading}
              downloadQuote={downloadQuotationQuery}
              isPendingLPOUpload={Boolean(orderSummary?.is_pending_lpo_upload)}
              isLPOUploading={uploadLPOResult.isLoading}
              uploadLPO={() => {
                LPOFileInputRef.current?.click()
              }}
              downloadLPO={downloadLPOQuery}
              isLPODownloading={downloadLPOResult.isLoading}
              lpoWasUploaded={orderSummary?.lpo_was_uploaded ?? false}
              duplicateOrder={() => redirect(routes.orderDuplicateChooseJobs, { orderId })}
              showQuoteDownload={!orderSummary?.is_draft && !Boolean(orderSummary?.is_pending_rates_review)}
            />
            <div className="OrderSummary__container">
              <OrderSummaryWelcome
                openOrderExtensionPopup={() => setOrderExtensionPopupVisible(true)}
                pendingConfirmation={Boolean(orderSummary?.is_pending_client_approval)}
                pendingRates={Boolean(orderSummary?.is_pending_rates_review)}
                corporateDetailsVerificationStatus={Number(orderSummary?.corporate_details_verification_status)}
              />
              <Separator.Vertical height={31} />
              {showDownloadQuoteActionAlert && (
                <Fragment>
                  <ActionAlert
                    theme={Theme.Orange}
                    withCloseButton={false}
                    title={t('order.quotation_download_title')}
                    actionText={t('order.quotation_download_action_text')}
                    action={downloadQuotationQuery}
                    isActionInProgress={downloadQuotationResult.isLoading}
                    actionInProgressText={t('order.quotation_download_in_progress_text')}
                  />
                  <Separator.Vertical height={Boolean(orderSummary?.is_pending_lpo_upload) ? 10 : 31} />
                </Fragment>
              )}
              {Boolean(orderSummary?.is_pending_lpo_upload) && (
                <Fragment>
                  <ActionAlert
                    theme={Theme.Orange}
                    withCloseButton={false}
                    title={t('order.lpo_upload_title')}
                    actionText={t('order.lpo_upload_action_text')}
                    action={() => {
                      LPOFileInputRef.current?.click()
                    }}
                    isActionInProgress={uploadLPOResult.isLoading}
                    actionInProgressText={t('order.lpo_upload_in_progress_text')}
                  />
                  <Separator.Vertical height={31} />
                </Fragment>
              )}
              <input type="file" style={{ display: 'none' }} ref={LPOFileInputRef} onChange={handleUploadLPO} />
              <SectionTitle image={fileIcon} title={t('order.order_summary')} />
              <Separator.Vertical height={14} />
              <OrderSummaryCard
                orderId={orderId}
                showCompleteDetails={false}
                showAmend={false}
                location={orderSummary?.locations[0].name}
                paymentTerms={orderSummary?.payment_terms_days}
                startDate={orderSummary?.start_date ? moment(orderSummary.start_date) : null}
                endDate={orderSummary?.start_date ? moment(orderSummary.end_date) : null}
              />
              <Separator.Vertical height={24} />
              {orderSummary && orderSummary.jobs.length > 0 && (
                <>
                  <SectionTitle image={inviteIcon} title={t('order.jobs')} />
                  <Separator.Vertical height={14} />
                </>
              )}
              {orderSummary?.jobs.map((job, i) => {
                const invitedSPs = invitedPastSPsList.filter(sp => job.invited_sp_ids.includes(sp.id))

                return (
                  <Fragment key={job.id}>
                    <OrderSummaryJobsCard
                      work_category_name={job.work_category_name}
                      start_date={moment(job.start_date)}
                      end_date={moment(job.end_date)}
                      hours={job.hours}
                      currency={job.currency}
                      shifts={job.shifts}
                      hourly_rate={job.hourly_rate}
                      quantity={job.quantity}
                      transportation_costs={job.transportation_costs}
                      underReview={Boolean(orderSummary.is_pending_rates_review)}
                      invitedSPs={invitedSPs}
                    />
                    {i !== orderSummary.jobs.length - 1 && <Separator.Vertical height={14} />}
                  </Fragment>
                )
              })}
              {orderSummary?.is_pending_rates_review === 0 && (
                <>
                  <Separator.Vertical height={24} />
                  <SectionTitle image={costIcon} title={t('order.cost')} />
                  <Separator.Vertical height={14} />
                  <OrderSummaryCostCard
                    sub_total={orderSummary.sub_total as number}
                    tax={orderSummary.tax as number}
                    tax_percent={orderSummary.tax_percentage as number}
                    transportation_total={orderSummary.transportation_total as number}
                    total={orderSummary.total as number}
                    currency={orderSummary.currency as string}
                  />
                </>
              )}
              <Separator.Vertical height={31} />
              {!hideConfirmation && showNonUpfrontConfirmationButton && (
                <>
                  <ProtectedRoute
                    showDefaultFallbackUI={false}
                    allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Finance]}
                  >
                    {confirmTermsQuery.isLoading ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        <AcceptTerms
                          isTermsAndConditionsAccepted={isTermsAndConditionsAccepted}
                          requestConfirmTerms={requestConfirmTerms}
                          apiHost={apiHost}
                        />
                        <Separator.Vertical height={31} />
                      </>
                    )}
                    <Button
                      onClick={() => requestConfirmOrder({ orderId })}
                      disabled={disableConfirmButton}
                      className={`OrderSummary__confirmButton ${disableConfirmButton ? 'disabled' : ''}`}
                    >
                      {t('order.confirm')}
                    </Button>
                  </ProtectedRoute>
                  <Separator.Vertical height={24} />
                </>
              )}
              {!Boolean(orderSummary?.is_published) && (
                <Button
                  onClick={() => {
                    if (orderSummary?.is_draft) {
                      redirect(routes.orderDraft, { orderId })
                    } else {
                      setAmendModalVisible(true)
                    }
                  }}
                  className="OrderSummary__amendButton"
                >
                  {t('order.amend_order')}
                </Button>
              )}
              <Separator.Vertical height={140} />
            </div>
            {!hideConfirmation && showAcceptTerms && (
              <StickyPanel contentStyle={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <div className="OrderSummary__PayButton__termsBlock">
                  <AcceptTerms
                    isTermsAndConditionsAccepted={isTermsAndConditionsAccepted}
                    requestConfirmTerms={requestConfirmTerms}
                    apiHost={apiHost}
                  />
                </div>
                <div className="OrderSummary__PayButton__payBlock">
                  <div className="OrderSummary__PayButton__description">
                    <span className="OrderSummary__PayButton__description__title">{t('payment.overdue_balance')}</span>
                    <span className="OrderSummary__PayButton__description__amount">
                      {orderSummary.currency} {orderSummary.total}
                    </span>
                  </div>
                  <Button
                    onClick={pay}
                    style={{ margin: '0' }}
                    disabled={disableConfirmButton}
                    className={`OrderSummary__confirmButton ${disableConfirmButton ? 'disabled' : ''}`}
                  >
                    {getUpfrontPaymentIdResult.isLoading ? 'loading' : t('payment.pay_now')}
                  </Button>
                </div>
              </StickyPanel>
            )}
            {!showAcceptTerms && (
              <StickyPanel contentStyle={{ justifyContent: 'center' }}>
                <Button
                  onClick={() => redirect(routes.orderDuplicateChooseJobs, { orderId })}
                  className="OrderSummary__confirmButton"
                >
                  {t('duplicate_order.duplicate_order')}
                </Button>
              </StickyPanel>
            )}
          </div>
        )}
        <ExtendOrderPopup
          isOpen={orderExtensionPopupVisible}
          close={() => setOrderExtensionPopupVisible(false)}
          clearOnClose={false}
        />
        <AmendModal
          modalVisible={amendModalVisible}
          onClose={() => {
            setAmendModalVisible(false)
          }}
          orderId={orderId}
        />
      </>
    )
  }),
)

export default OrderSummary
