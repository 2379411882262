import { Instance, types, cast, addMiddleware } from 'mobx-state-tree'

import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export const RequirementsModel = types
  .model('Requirements', {
    list: types.maybeNull(types.array(types.number)),
    error: types.maybeNull(types.string),
  })
  .actions(self => ({
    add(id: number) {
      if (self.list) {
        self.list.push(id)
      } else {
        self.list = cast([id])
      }
    },

    remove(id: number) {
      self.list = cast(self.list?.filter(item => item !== id))
    },

    set(requirements: number[]) {
      self.list = cast(requirements)
    },

    validate() {
      self.error = null
      return true
    },
  }))
  .views(self => ({
    has(id: number) {
      return Boolean(self.list?.includes(id))
    },
  }))

export function createRequirementsMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.requirements) {
      return abort(targetInstance.requirements.validate())
    }
    return next(call)
  })
}

export type RequirementsInstance = Instance<typeof RequirementsModel>
