import { types, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import { v4 as uuidv4 } from 'uuid'

import { DraftJobModel, DraftJobInstance, DraftJobSnapshot, createDraftJobInstance } from './draft-job'

export const DraftJobsModel = types
  .model('DraftJobs', {
    jobs: types.map(DraftJobModel),
  })
  .actions(self => ({
    createDraftJob(job?: Omit<DraftJobSnapshot, 'id'>) {
      const jobDraftId = uuidv4()
      const draftJob = createDraftJobInstance(
        (job ? { ...job, id: jobDraftId } : { id: jobDraftId }) as DraftJobSnapshot,
      )
      self.jobs.set(jobDraftId, draftJob)
      return draftJob
    },
  }))
  .views(self => ({
    getDraftJob(id: string) {
      return self.jobs.get(id) as DraftJobInstance
    },
  }))

export type DraftJobs = SnapshotIn<typeof DraftJobsModel>
export type DraftJobsSnapshot = SnapshotOut<typeof DraftJobsModel>
