import { useState, useContext, useEffect, FunctionComponent } from 'react'

import { load, save } from '../../services/storage'
import { OnboardingContent } from '../page-onboarding/page-onboarding'
import { OnboardingWrapper } from './onboarding-wrapper'
import { UIStateContext } from '../ui-state-management/ui-state-management'

export function withOnboardingComponent<T extends Record<string, unknown>>(
  Component: FunctionComponent<T>,
  Onbording: OnboardingContent,
  helpButtonAdditionalStyle?: object,
) {
  return function ComponentWithOnboarding(props: T) {
    const [onboardingVisible, setOnboardingVisible] = useState(false)
    const { showHelpButton } = useContext(UIStateContext)

    useEffect(() => {
      const componentName = Component.displayName ?? Component.name
      if (!componentName) {
        throw new Error('Expected to receive a name of component')
      }
      const shownBefore = load(componentName)
      if (!shownBefore) {
        setOnboardingVisible(true)
      }
      save(componentName, true)
    }, [])

    return (
      <OnboardingWrapper
        Component={Component}
        Content={Onbording}
        onboardingVisible={onboardingVisible}
        showHelpButton={showHelpButton}
        componentProps={props}
        setOnboardingVisible={setOnboardingVisible}
        helpButtonAdditionalStyle={helpButtonAdditionalStyle}
      />
    )
  }
}
