import { t } from '../../../../translations'
import { images } from '../../assets/order-creation'

export const schedulingOptionsPage = {
  title: t('order_creation_onboarding.scheduling_options_title'),
  content: [
    { image: { src: images.schedulingOptionsImg, className: 'Onboarding__bigImg' } },
    { description: t('order_creation_onboarding.scheduling_options') },
  ],
}
