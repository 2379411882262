import { types } from 'mobx-state-tree'

const InvoicesList = types
  .model('InvoicesList', {
    searchFilters: types.model({
      locationId: types.maybeNull(types.number),
      designationId: types.maybeNull(types.number),
      dateFrom: types.maybeNull(types.string),
      dateTo: types.maybeNull(types.string),
      orderId: types.maybeNull(types.number),
      isOverdue: types.boolean,
    }),
  })
  .actions(self => ({
    setLocationId(locationId: number | null) {
      self.searchFilters.locationId = locationId
    },
    setDesignationId(designationId: number | null) {
      self.searchFilters.designationId = designationId
    },
    setDateFrom(dateFrom: string | null) {
      self.searchFilters.dateFrom = dateFrom
    },
    setDateTo(dateTo: string | null) {
      self.searchFilters.dateTo = dateTo
    },
    setOrderId(orderId: number | null) {
      self.searchFilters.orderId = orderId
    },
    setIsOverdue(isOverdue: boolean) {
      self.searchFilters.isOverdue = isOverdue
    },
    reset() {
      self.searchFilters.locationId = null
      self.searchFilters.designationId = null
      self.searchFilters.dateFrom = null
      self.searchFilters.dateTo = null
      self.searchFilters.orderId = null
      self.searchFilters.isOverdue = false
    },
  }))
  .views(self => ({
    get activeFiltersCount() {
      return [
        self.searchFilters.locationId,
        self.searchFilters.designationId,
        self.searchFilters.dateFrom,
        self.searchFilters.dateTo,
        self.searchFilters.orderId,
        self.searchFilters.isOverdue,
      ].filter(value => Boolean(value)).length
    },
  }))

export const invoicesListStore = InvoicesList.create({
  searchFilters: {
    locationId: null,
    designationId: null,
    dateFrom: null,
    dateTo: null,
    orderId: null,
    isOverdue: false,
  },
})
