import { useTranslation } from 'react-i18next'

import { Schedule, ScheduleShift, ShiftStatus } from '../../../../../../../models/schedule/types'
import { Time } from './time'
import './shift-time.scss'
import { formatDateToHhMm } from '../../../../../../../utils/datetime-utils'

type ShiftTimeColumn = {
  startTimeSeconds: number
  endTimeSeconds: number
  shift: ScheduleShift
  jobSchedule: Schedule
}

export function ShiftTime({ shift, startTimeSeconds, endTimeSeconds, jobSchedule }: ShiftTimeColumn) {
  const { t } = useTranslation()

  const editingDisabled = shift.status === ShiftStatus.Completed || shift.status === ShiftStatus.Started

  const startDate = new Date(startTimeSeconds * 1000)
  const startTimeFormatted = formatDateToHhMm(startDate)
  const endDate = new Date(endTimeSeconds * 1000)
  const endTimeFormatted = formatDateToHhMm(endDate)

  const getUpdatedTime = (value: string, date: Date) => {
    const [hours, minutes] = value.split(':')
    const newStartTime = new Date(date)
    newStartTime.setHours(parseInt(hours))
    newStartTime.setMinutes(parseInt(minutes))
    return Math.floor(newStartTime.getTime() / 1000)
  }

  return (
    <div className="ShiftTime">
      <Time
        value={startTimeFormatted}
        label={t('all_jobs.start')}
        disabled={editingDisabled}
        handleChange={value => {
          jobSchedule.modifyActiveShiftsTime({
            shiftId: shift.id,
            startTime: getUpdatedTime(value, startDate),
          })
        }}
      />
      <Time
        value={endTimeFormatted}
        label={t('all_jobs.end')}
        disabled={editingDisabled}
        handleChange={value => {
          jobSchedule.modifyActiveShiftsTime({
            shiftId: shift.id,
            endTime: getUpdatedTime(value, endDate),
          })
        }}
      />
    </div>
  )
}
