import { OnboardingContent } from '../../../page-onboarding/page-onboarding'
import { t } from '../../../../translations'
import { routes } from '../../../../routes'
import { schedulingOptionsPage } from '../pages/scheduling-options'

export const schedulingOptionsOnboarding: OnboardingContent = {
  title: t('order_creation_onboarding.title'),
  paths: [`${routes.jobDraftDetails.path}/scheduleType`],
  pages: [schedulingOptionsPage],
}
