import { useTranslation } from 'react-i18next'

import { Link } from '../../../../services/router'
import { FieldError } from '../../../../components/form/field-error/field-error'
import { Input } from '../../../../components/form/input/input'
import { PasswordInput } from '../../../../components/password-input/password-input'
import { Separator } from '../../../../components/app-layout/separator/separator'
import { routes } from '../../../../routes'
import { UIProps } from '../types'
import appLogoImg from '../../../../components/assets/app-logo.svg'
import './login.scss'
import { MarketSelector } from '../../../../components/market-selector/market-selector'
import { isCurrentMarketLaunched } from '../../../../services/market-service'

export const LoginUI = (props: UIProps) => {
  const { t } = useTranslation()

  return (
    <div className="Login">
      <div className="Login__topContainer">
        <img src={appLogoImg} className="Login__logo" />
        <MarketSelector redirectPath="login" />
      </div>
      <Separator.Vertical height={45} />
      <h1 className="Login__title">{t('login.good_to_see_you_back')}</h1>
      <Separator.Vertical height={5} />
      <h2 className="Login__subtitle">{t('login.sign_in_to_account')}</h2>
      <Separator.Vertical height={53} />
      <FieldError errors={props.emailErrors}>
        <Input type="email" label={t('login.email')} value={props.email} handleChange={props.handlEmailChange} />
      </FieldError>
      <Separator.Vertical height={29} />
      <FieldError errors={props.passwordErrors}>
        <PasswordInput
          label={t('login.password')}
          value={props.password}
          handleChange={props.handlPasswordChange}
          hidePasswordByDefault
        />
      </FieldError>
      <Separator.Vertical height={36} />
      <div className="Login__underform">
        <Link route={routes.forgotPassword} params={{}}>
          {t('login.forgot_password_question')}
        </Link>
      </div>
      <Separator.Vertical height={53} />
      <button onClick={props.handleLoginButtonPress} type="submit" className="Login__button">
        {props.submitting ? t('login.signin_in') : t('login.sign_in')}
      </button>
      <Link
        route={isCurrentMarketLaunched() ? routes.signUpWithOrder : routes.signUp}
        params={{}}
        className="Login__SignUp_link"
        handleClick={props.handleSignUpLinkPress}
      >
        {t('login.sign_up_link_text')}
      </Link>
    </div>
  )
}
