import { DraftJobInstance } from '../store/draft-job'
import { JobForm } from '../../../models/job'

export const jobFormFromDraftJobInstance = (job: DraftJobInstance): JobForm => {
  return {
    work_category_id: job.category.id as number,
    quantity: job.quantity.value as number,
    client_location_id: job.location.value?.id as number,
    public_transport_accessibility: job.transport.value,
    start_date: job.schedule.dateBoundaries.start_date as string,
    end_date: job.schedule.dateBoundaries.end_date as string,
    is_recurring: job.schedule.dateBoundaries.open_ended ? 1 : 0,
    contact_user_id: job.manager.id as number,
    work_requirement_ids: job.requirements.list as number[],
    instructions: job.description.value as string,
    seniority_level: Number(job.experience.value),
    gender: job.gender.value ? Number(job.gender.value) : null,
    uniform_id: job.dresscode.id as number,
    invited_sp_ids: job.invitedSPs.list as number[],
    languages: job.languages.list as number[],
    certificates: job.documents.list as number[],
    is_automatic_checkout: 1,
    intervals: job.schedule.distinctTimeSchedule.map as Map<
      string,
      {
        start_time: string
        end_time: string
        selected: boolean
      }
    >,
    assignment_type: job.manualSelection.manual_selection ? 1 : 2,
    is_accreditation_required: job.accreditation.value ? 1 : 0,
    is_accommodation_offered: job.accommodation.value ? 1 : 0,
    is_interview_required: job.manualSelection.use_interview ? 1 : 0,
    boarding_type: job.boarding.value as number,
  }
}
