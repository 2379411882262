import { useState, useRef, useMemo, useEffect } from 'react'
import { observer } from 'mobx-react'
import { ChangeEvent } from 'react'
import { ApiErrorTypes } from 'ogram-react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useMutation, useStore } from '../../../../../utils/mst-hooks'
import ApplicantCard from '../applicant-card/applicant-card'
import applicantsIcon from '../../assets/applicants.svg'
import dropDownIcon from '../../assets/down-arrow.svg'
import Spacer from '../../../../order/components/spacer/spacer'
import LoadingSpinner from '../../../../../components/loading-spinner/loading-spinner'
import { ChiefPopup } from '../chief-popup/chief-popup'
import './applicants-section.scss'
import { withErrorBoundary } from '../../../../../components/error/with-error-boundary'
import { Interview } from '../../../components/interview/interview'
import { CherryPickingApplicantSnapshot } from '../../../../../models/cherry-picking-applicants'
import { Interview as InterviewInstance } from '../../../../../models/interview'
import { trackingService, TrackedEvent } from '../../../../../services/tracking-service'

enum sortByCategories {
  rating = 'rating',
  experience = 'years_experience',
  age = 'age',
}

type ApplicantsSectionInterface = {
  jobId: string
  shiftId?: number
}

const ApplicantsSection = withErrorBoundary(
  observer(({ jobId, shiftId }: ApplicantsSectionInterface) => {
    const {
      currentApplicant,
      applicantsView,
      setCurrentApplicant,
      getCherryPickingApplicantsApiCall,
      getInterviewByJobId,
      getInterviewQuestions,
      acceptApplicantApiCall,
      getSpAnswer,
    } = useStore().data
    const applicants = applicantsView()
    const [sortBy, setSortBy] = useState('rating')
    const [isSortingLoading, setIsSortingLoading] = useState(false)
    const [isPaginationLoading, setIsPaginationLoading] = useState(false)
    const [showChiefModal, setShowChiefModal] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState<{ qId: number; qIndex: number } | null>(null)
    const [getCherryPickingApplicantsQuery, getCherryPickingApplicants] = useMutation(
      getCherryPickingApplicantsApiCall,
      {
        onSuccess: () => {
          setIsSortingLoading(false)
          setIsPaginationLoading(false)
        },
      },
    )
    const [, acceptApplicant] = useMutation(acceptApplicantApiCall, {
      onSuccess: () => {
        toast.success(t('job_cherry_picking.applicant_assigned'))
      },
      onError: error => {
        toast.error(error.data.message)
      },
      filterError: [ApiErrorTypes.ClientError],
    })

    const showedChief = useRef(false)
    const chiefOg = useMemo(() => {
      if (applicants.list.length === 0) {
        return null
      }
      return applicants.list.find((applicant: CherryPickingApplicantSnapshot) => applicant.is_chief)
    }, [applicants])
    useEffect(() => {
      if (chiefOg && !showedChief.current) {
        setShowChiefModal(true)
        showedChief.current = true
        trackingService.track(TrackedEvent.ChiefCongratulationsPopupShown)
      }
    }, [chiefOg, showedChief.current])

    const onSortApplicants = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
      setIsSortingLoading(true)
      setSortBy(value)
      getCherryPickingApplicants({
        jobId: Number(jobId),
        sortBy: value,
        shiftId,
      })
    }
    const onNextPage = () => {
      setIsPaginationLoading(true)
      getCherryPickingApplicants({
        jobId: Number(jobId),
        sortBy,
        page: applicants.paginator?.next_page ?? undefined,
        shiftId,
      })
    }

    const { t } = useTranslation()

    return (
      <div className="ApplicantsSection">
        <div className="ApplicantsSection__headerContainer">
          <div className="ApplicantsSection__titleContainer">
            <img src={applicantsIcon} />
            <h3 className="ApplicantsSection__title">
              {t('job_cherry_picking.total_applicants', { count: applicants.paginator?.total })}
            </h3>
          </div>
          <div className="ApplicantsSection__sorting">
            <h4 className="ApplicantsSection__sortBy">{t('job_cherry_picking.sort_by')} </h4>
            <select value={sortBy} onChange={onSortApplicants} className="ApplicantsSection__sortingDropDown">
              <option value={sortByCategories.rating}>{t('job_cherry_picking.rating')}</option>
              <option value={sortByCategories.experience}>{t('job_cherry_picking.experience')}</option>
              <option value={sortByCategories.age}>{t('job_cherry_picking.age')}</option>
            </select>
            <img src={dropDownIcon} />
          </div>
        </div>
        <Spacer space={24} />
        {getCherryPickingApplicantsQuery.isLoading && isSortingLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {applicants.list.map((applicant, i: number) => (
              <div key={i}>
                <ApplicantCard
                  jobId={jobId}
                  applicant={applicant}
                  shiftId={shiftId}
                  onPlayAnswersPress={() => {
                    setCurrentApplicant(applicant.id)
                    const interview = getInterviewByJobId(parseInt(jobId)) as InterviewInstance
                    const questionIds = getInterviewQuestions(interview.id)
                    const applicantQuestions = questionIds.filter(q => {
                      const answer = getSpAnswer({ spId: applicant.id, interviewId: interview.id, questionId: q })
                      return Boolean(answer)
                    })
                    setCurrentQuestion({ qId: applicantQuestions[0], qIndex: 0 })
                  }}
                />
                <Spacer space={i === applicants.list.length - 1 ? 28 : 14} />
              </div>
            ))}
            {applicants.paginator?.next_page ? (
              getCherryPickingApplicantsQuery.isLoading && isPaginationLoading ? (
                <LoadingSpinner />
              ) : (
                <button onClick={onNextPage} className="ApplicantsSection__seeMore">
                  {t('job_cherry_picking.see_more')}
                </button>
              )
            ) : null}
          </>
        )}
        {showChiefModal && chiefOg && (
          <ChiefPopup
            name={chiefOg.name}
            imgSrc={chiefOg.image_url ?? ''}
            onClose={() => {
              setShowChiefModal(false)
            }}
          />
        )}
        <Interview
          applicantsType="multiple"
          jobId={parseInt(jobId)}
          showInitialQuestion={currentQuestion}
          onAcceptPress={() => {
            acceptApplicant({
              sp_id: String((currentApplicant as CherryPickingApplicantSnapshot).id),
              job_id: parseInt(jobId),
              shift_ids: shiftId ? [shiftId] : undefined,
            })
          }}
        />
      </div>
    )
  }),
)

export default ApplicantsSection
