import { observer } from 'mobx-react'

import { SpShiftStatus } from '../../../../../../../models/schedule/types'
import './status.scss'

export enum StatusType {
  shiftCompleted,
  shiftStarted,
  shiftUpcoming,
  spCompleted,
  spStarted,
  spUpcoming,
  spCanceled,
  spEmpty,
  spPending,
  noStatus,
}

export function getShiftStatus(status: SpShiftStatus): StatusType {
  switch (status) {
    case SpShiftStatus.completed:
      return StatusType.spCompleted
    case SpShiftStatus.started:
      return StatusType.spStarted
    case SpShiftStatus.accepted:
      return StatusType.spUpcoming
    case SpShiftStatus.canceled:
      return StatusType.spCanceled
    case SpShiftStatus.pendingConfirmation:
      return StatusType.spPending
    case SpShiftStatus.irrelevant:
      return StatusType.noStatus
  }
}

type StatusComponentProps = {
  status: StatusType
  title: string
}

function getClassnameByStatus(status: StatusType) {
  switch (status) {
    case StatusType.shiftCompleted:
      return 'completed-shift'
    case StatusType.shiftStarted:
      return 'started-shift'
    case StatusType.shiftUpcoming:
      return 'upcoming-shift'
    case StatusType.spCompleted:
      return 'completed-sp'
    case StatusType.spStarted:
      return 'started-sp'
    case StatusType.spUpcoming:
      return 'upcoming-sp'
    case StatusType.spCanceled:
      return 'not-attended-sp'
    case StatusType.spEmpty:
      return 'upcoming-empty'
    case StatusType.spPending:
      return 'pending-sp'
    case StatusType.noStatus:
      return ''
  }
}

function StatusComponent({ status, title }: StatusComponentProps) {
  return (
    <div className={`Status ${getClassnameByStatus(status)}`}>
      <span className="Status__label">{title}</span>
    </div>
  )
}

export const Status = observer(StatusComponent)
