import { useState, useContext, useMemo, useEffect, ComponentType } from 'react'
import { observer } from 'mobx-react'

import { routerContext } from '../../services/router'
import { useStore } from '../../utils/mst-hooks'
import { load, save } from '../../services/storage'
import { onboardingsForPages } from '../onboardings'
import { OnboardingWrapper } from './onboarding-wrapper'
import { UIStateContext } from '../ui-state-management/ui-state-management'

export function withOnboardingPage<T extends Record<string, unknown>>(Component: ComponentType<T>) {
  function PageWithOnboarding(props: T) {
    const [onboardingVisible, setOnboardingVisible] = useState(false)
    const router = useContext(routerContext)
    const { logged } = useStore().data
    const { showHelpButton } = useContext(UIStateContext)

    const onboarding = useMemo(() => {
      return onboardingsForPages.find(item => {
        return item.paths.find(pathItem => {
          if (pathItem !== router.url.path) {
            return
          }

          return item
        })
      })
    }, [router.url.path])

    useEffect(() => {
      if (!onboarding || !logged) return
      for (const pathItem of onboarding.paths) {
        const shownBefore = load(pathItem)
        if (!shownBefore) {
          setOnboardingVisible(true)
        }
        save(pathItem, true)
      }
    }, [onboarding, logged])

    return (
      <OnboardingWrapper
        Component={Component}
        Content={onboarding}
        onboardingVisible={onboardingVisible && Boolean(onboarding)}
        showHelpButton={Boolean(onboarding) && logged && showHelpButton}
        componentProps={props}
        setOnboardingVisible={setOnboardingVisible}
      />
    )
  }

  return observer(PageWithOnboarding)
}
