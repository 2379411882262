import { useRef, Fragment } from 'react'
import { observer } from 'mobx-react'

import { formatDateToDdd, getWeekDates } from '../../../../../../utils/datetime-utils'
import './calendar-week.scss'

export function CalendarWeekComponent({ firstWeekDate }: { firstWeekDate: Date }) {
  const todayDate = useRef(new Date()).current
  return (
    <div className="CalendarWeek">
      <div className="CalendarWeek__emptyColumn" />
      {getWeekDates(firstWeekDate).map(date => {
        const weekdayName = formatDateToDdd(date)
        const isActive =
          todayDate.getFullYear() === date.getFullYear() &&
          todayDate.getMonth() === date.getMonth() &&
          todayDate.getDate() === date.getDate()
        const day = date.getDate()
        return (
          <Fragment key={day}>
            <div className="CalendarWeek__separator" />
            <div className={`CalendarWeek__column ${isActive ? 'active' : ''}`}>
              <div className="CalendarWeek__column__container">
                <span className={`CalendarWeek__column__container__day ${isActive ? 'active' : ''}`}>{day}</span>
                <span className={`CalendarWeek__column__container__weekday ${isActive ? 'active' : ''}`}>
                  {weekdayName}
                </span>
              </div>
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

export const CalendarWeek = observer(CalendarWeekComponent)
