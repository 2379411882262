import { useTranslation } from 'react-i18next'

import jobIcon from '../../../../../../../components/assets/job-green.svg'
import { CollapseToggle } from './collapse-toggle'
import './job-column.scss'

export type JobColumnProps = {
  workCategory: string
  quantity: number
  location: string
  orderId: number
  setCollapsed: () => void
}

export function JobColumn({ workCategory, quantity, location, orderId, setCollapsed }: JobColumnProps) {
  const { t } = useTranslation()

  const title = `${workCategory} (${quantity})`
  return (
    <div onClick={setCollapsed} className="JobColumn">
      <img src={jobIcon} className="JobColumn__img" />
      <div className="JobColumn__textContainer">
        <span
          className={`JobColumn__textContainer__subtitle JobColumn__textContainer__subtitle--top ${
            location.length > 25 ? 'JobColumn__textContainer__subtitle_size_small' : ''
          }`}
        >
          {t('order.order_number', { id: orderId })}
        </span>
        <span
          className={`JobColumn__textContainer__title ${
            title.length > 25 ? 'JobColumn__textContainer__title_size_small' : ''
          }`}
        >
          {title}
        </span>
        <span
          className={`JobColumn__textContainer__subtitle ${
            location.length > 25 ? 'JobColumn__textContainer__subtitle_size_small' : ''
          }`}
        >
          {location}
        </span>
      </div>
      <CollapseToggle />
    </div>
  )
}
