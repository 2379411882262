import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../components/app-layout/separator/separator'
import './all-jobs.scss'
import { Header } from '../../../components/app-layout'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { CalendarWrapper } from './components/calendar/calendar-wrapper/calendar-wrapper'
import { ScanQrButton } from '../../../components/scan-qr-button/scan-qr-button'

export const AllJobsPage = withErrorBoundary(
  observer((props: { date: string }) => {
    const { t } = useTranslation()

    return (
      <div className="AllJobs">
        <Header title={t('all_jobs.title')} rightComponent={<ScanQrButton />} />
        <Separator.Vertical height={30} />
        <CalendarWrapper initialDate={new Date(Date.parse(props.date))} />
      </div>
    )
  }),
)
