import './filters-popup.scss'

import { useTranslation } from 'react-i18next'

import { Popup } from '../../popup/popup'
import filterIcon from '../../assets/filter.svg'
import { Toggle } from '../../toggle/toggle'
import { ButtonPrimary } from '../../button/button-primary/button-primary'
import { ButtonSecondary } from '../../button/button-secondary/button-secondary'
import { LocationSelect } from '../../form/location-select/location-select'
import { DesignationSelect } from '../../form/designation-select/designation-select'
import { DateInput } from '../../date-input/date-input'
import { Input } from '../../form/input/input'

type Field = 'locationId' | 'designationId' | 'isFavorite' | 'startDate' | 'endDate' | 'orderId' | 'isOverdue'

type InputProps = {
  isOpen: boolean
  close: () => void
  locationId?: number | undefined
  setLocationId?: (value: number | undefined) => void
  designationId?: number | undefined
  setDesignationId?: (value: number | undefined) => void
  isFavorite?: boolean
  setIsFavorite?: (value: boolean) => void
  startDate?: string | undefined
  setStartDate?: (value: string) => void
  startDateLabel?: string | null
  endDate?: string | undefined
  setEndDate?: (value: string) => void
  endDateLabel?: string | null
  orderId?: number | undefined
  setOrderId?: (value: number | undefined) => void
  isOverdue?: boolean
  setIsOverdue?: (value: boolean) => void
  reset: () => void
  apply: () => void
  fields: Field[]
}

export const FiltersPopup = ({
  isOpen,
  close,
  locationId,
  setLocationId,
  designationId,
  setDesignationId,
  isFavorite,
  setIsFavorite,
  orderId,
  setOrderId,
  isOverdue,
  setIsOverdue,
  reset,
  apply,
  startDate,
  setStartDate,
  startDateLabel,
  endDate,
  setEndDate,
  endDateLabel,
  fields,
}: InputProps) => {
  const { t } = useTranslation()

  return (
    <Popup
      isActive={isOpen}
      content={
        <div className="FiltersPopup">
          <div className="FiltersPopup__titleContainer">
            <img src={filterIcon} />
            <span>{t('common.filters')}</span>
          </div>
          {fields.includes('orderId') && (
            <div className="FiltersPopup__filterContainer">
              <Input
                label={t('common.order_id')}
                type="number"
                value={orderId}
                handleChange={value => setOrderId?.(Number(value))}
                className="FiltersPopup__text"
              />
            </div>
          )}
          {fields.includes('locationId') && (
            <div className="FiltersPopup__filterContainer">
              <LocationSelect
                label={t('common.location')}
                value={locationId}
                onChange={value => {
                  setLocationId?.(value)
                }}
                className="FiltersPopup__select"
              />
            </div>
          )}
          {fields.includes('designationId') && (
            <div className="FiltersPopup__filterContainer">
              <DesignationSelect
                label={t('common.designation')}
                value={designationId}
                onChange={value => {
                  setDesignationId?.(value)
                }}
                className="FiltersPopup__select"
              />
            </div>
          )}
          {fields.includes('isFavorite') && (
            <div className="FiltersPopup__filterContainer">
              <div className="FiltersPopup__toggle" onClick={() => setIsFavorite?.(!isFavorite)}>
                <span>{t('staff.favorite_only')}</span>
                <Toggle active={Boolean(isFavorite)} onToggle={() => {}} />
              </div>
            </div>
          )}
          {fields.includes('isOverdue') && (
            <div className="FiltersPopup__filterContainer">
              <div className="FiltersPopup__toggle" onClick={() => setIsOverdue?.(!isOverdue)}>
                <span>{t('common.is_overdue')}</span>
                <Toggle active={Boolean(isOverdue)} onToggle={() => {}} />
              </div>
            </div>
          )}
          {fields.includes('startDate') && (
            <div className="FiltersPopup__filterContainer">
              <DateInput
                label={startDateLabel ?? t('job_draft_details.start_date')}
                value={startDate}
                handleChange={value => {
                  setStartDate?.(value)
                }}
              />
            </div>
          )}
          {fields.includes('endDate') && (
            <div className="FiltersPopup__filterContainer">
              <DateInput
                label={endDateLabel ?? t('job_draft_details.end_date')}
                value={endDate}
                handleChange={value => {
                  setEndDate?.(value)
                }}
              />
            </div>
          )}
          <div className="FiltersPopup__actions">
            <ButtonPrimary text={t('staff.apply')} action={apply} />
            <ButtonSecondary text={t('staff.reset')} action={reset} />
          </div>
        </div>
      }
      close={close}
    />
  )
}
